<div>
    <div class="popup-close-header">
        <span class="popup-close" (click)="onNoClick()">X</span>
    </div>
    
    <p><img src="./assets/images/warning-icon.png" style="vertical-align: bottom;margin-right: 5px;">  <b>Estimado Cliente,</b></p>
    <p>
        Su vehículo ya posee un agendamiento:
    </p>
    <ul>
        <li><b>Reserva: </b>{{ codigo }}</li>
        <li><b>Sucursal: </b>{{ sucursal }}</li>
        <li><b>Horario: </b>{{ fecha}} {{ hora }}</li>
    </ul>

    <p>* Si desea realizar otro agendamiento debe seleccionar una fecha diferente a su cita actual.</p>
</div>