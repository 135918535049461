<mat-toolbar class="header">
  <a href="https://www.divemotor.com" target="_blank"><img src="/assets/images/logo-divemotor.png" width="175"></a> 
  <span class="example-spacer"></span>
  <div class="toolbar-info" *ngIf="brand != 'divemotor'">
    <img src="/assets/images/logos/{{brand}}.{{logotype}}" [alt]="brand" [title]="brand.toUpperCase()" height="70">
  </div>
</mat-toolbar>

<div class="app-content" [style.backgroundImage]="'url(' + globalbg + ')'">
  <router-outlet></router-outlet>
  <ngx-ui-loader></ngx-ui-loader>
</div>

<mat-toolbar class="footer">
  <div class="footer-brand">
    <div>
      <a href="https://www.divemotor.com" target="_blank"><img src="/assets/images/logo-divemotor.svg" alt="Logo Divemotor"></a>
    </div>
    <div class="rrss" style="width:200px;display:flex; align-items: center; ">
        <a target="_blank" href="https://www.facebook.com/divemotor"><img src="/assets/images/facebook-icon.svg" style="width:20px; height: 37px;"></a>
        <a target="_blank" href="https://www.youtube.com/user/divemotorperu"><img src="/assets/images/youtube-icon.svg" style="width:35px; height: 24px;"></a>
        <a target="_blank" href="https://www.linkedin.com/company/divemotor"><img src="/assets/images/linkedin-icon.svg" style="width:32px; height: 30.16px;"></a>
    </div>
  </div>
  <div class="footer-links">
    <div><p>Copyright © 2023 Diveimport S.A. <br> Todos los derechos reservados</p></div>
    <div class="box-complaints-book"><img src="/assets/images/ico-libro.svg" alt="Libro de Reclamaciones"><a target="_blank" href="https://apps.divemotor.com.pe/SqrWeb/faces/registroPublicoTicket.xhtml">LIBRO DE RECLAMACIONES</a></div>
  </div>
</mat-toolbar>

<a target="_blank" href="https://wa.me/5117122000?text=Hola%20necesito%20información" class="ws-button">
  <img src="assets/images/ws-sidebar.png" width="50" title="Contactar por WhatsApp">
</a>
