import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-popup-previus-data',
  templateUrl: './popup-previus-data.component.html',
  styleUrls: ['./popup-previus-data.component.scss']
})
export class PopupPreviusData implements OnInit{

  nombre: String = '';
  placa: String = '';
  hora: String = '';
  fecha: String = '';
  sucursal: String = '';
  asesor: String = '';
  codigo: String = '';

  constructor(
    public dialogRef: MatDialogRef<PopupPreviusData>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}


  ngOnInit() {
      this.nombre = this.data.reserva.NomCuenta;
      this.placa = this.data.reserva.Placa;

      let hora = this.data.reserva.Hora;
      let horaFormat = hora.slice(2, -4).trim();
      this.hora = horaFormat.replace('H', ':');

      let fecha = this.data.reserva.Fecha;
      this.fecha = moment(fecha).format('DD/MM/YYYY');

      this.sucursal = this.data.reserva.NomSucursal;
      this.asesor = this.data.reserva.NomAsesor + ' ' + this.data.reserva.ApellAsesor;
      this.codigo = this.data.reserva.CodReserva;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
