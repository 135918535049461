export const banners = {
    'mercedes-benz':[
      {path:'/assets/images/banners/banner-mercedes-benz.webp', position: 'center'},
      {path:'/assets/images/banners/banner-mercedes-benz-camiones.png', position: 'bottom'},
      {path:'/assets/images/banners/banner-mercedes-benz-sprinters.png', position: 'center'},
    ],
    'fiat':[
      {path:'/assets/images/banners/banner-fiat.jpg', position: 'center'},
    ],
    'jeep':[
      {path:'/assets/images/banners/banner-jeep.jpg', position: 'center right'},
    ],
    'ram':[
      {path:'/assets/images/banners/banner-ram.jpg', position: 'center'},
    ],
    'dodge':[
      {path:'/assets/images/banners/banner-dodge.jpg', position: 'top'},
    ],
    'divemotor':[
      {path:'/assets/images/banners/banner-divemotor.jpg', position: 'center'},
      {path:'/assets/images/banners/banner-mercedes-benz.webp', position: 'center'},
      {path:'/assets/images/banners/banner-fiat.jpg', position: 'center'},
      {path:'/assets/images/banners/banner-jeep.jpg', position: 'center right'},
      {path:'/assets/images/banners/banner-ram.jpg', position: 'center'},
      {path:'/assets/images/banners/banner-dodge.jpg', position: 'top'},
    ],
    'freightliner':[
      {path:'/assets/images/banners/banner-freightliner.png', position: 'center'},
    ]
}