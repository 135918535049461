<div class="material-content" style="padding-top: 125px;">
    <mat-card>
      
        <mat-horizontal-stepper [linear]="isLinear"  #stepper (selectionChange)="onStepChange($event)">
            <mat-step [stepControl]="firstFormGroup">

              <ng-template matStepLabel>
                Identificar Vehículo
              </ng-template>

              <ng-template matStepperIcon="edit">
                <img src="./assets/images/check-icon-orange.png">             
              </ng-template>

              <form [formGroup]="firstFormGroup" >                   
                <div>
                  <h2 class="form-title">Ingresar datos de su vehículo</h2>
                </div>

                <div class="form-responsive">
                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>PLACA</mat-label>
                      <input
                      matInput 
                      #placa
                      required
                      maxlength="7"
                      placeholder="ABC123 o ABC-123" 
                      formControlName="vehiculoPlaca"
                      (input)="onKey($event)"
                      (keydown.backspace)="clearPlacaField(false)"
                      >
                      <mat-icon matSuffix (click)="clearPlacaField()" *ngIf="placa.value.length > 5">close</mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline" *ngIf="placaConsultada">
                      <mat-label>MARCA</mat-label>
                      <mat-select
                        required
                        formControlName="vehiculoMarca"
                        (selectionChange)="onMarcaVehiculoSelected()"
                        >
                        <mat-option value="">Seleccione marca</mat-option>
                        <mat-option *ngFor="let marca of marcaVehiculoResponse" [value]="marca.codigo">
                          {{ marca.marca }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline" *ngIf="placaConsultada">
                      <mat-label>TIPO DE VEHÍCULO</mat-label>
                      <mat-select
                        required
                        formControlName="vehiculoFamilia"
                        (selectionChange)="onTipoVehiculoSelected()"
                        >
                        <mat-option value="">Seleccione tipo</mat-option>
                        <mat-option *ngFor="let tipo of tipoVehiculoResponse" [value]="tipo.codigo">
                          {{ tipo.tipo }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                  

                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline" *ngIf="placaConsultada">
                    <mat-label>MODELO</mat-label>
                    <mat-select
                      required
                      formControlName="vehiculoModelo"
                      >
                      <mat-option value="">Seleccione modelo</mat-option>
                      <mat-option *ngFor="let modelo of modeloVehiculoResponse" [value]="modelo.Mcodesd">
                        {{ modelo.Mcodesd }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                  
                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline"
                      *ngIf="placaConsultada"
                      
                      >
                      <mat-label>KILOMETRAJE ACTUAL <span *ngIf="['1','2'].includes(firstFormGroup.get('vehiculoFamilia').value)">(Opcional)</span></mat-label>
                      <input matInput type="number"
                      [required]="['3','4','5'].includes(firstFormGroup.get('vehiculoFamilia').value)"
                      placeholder="1000"
                      formControlName="vehiculoKilometraje"
                      onKeyPress="if(this.value.length==9) return false;"
                      >
                      <span matSuffix>km</span>
                    </mat-form-field>
                  </div>

                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" 
                      *ngIf="['3','4'].includes(firstFormGroup.get('vehiculoFamilia').value) && placaConsultada"
                      appearance="outline">
                      <mat-label>HORÓMETRO ACTUAL <span>(Opcional)</span></mat-label>
                      <input matInput type="number"
                      placeholder="100"
                      formControlName="vehiculoHorometro"
                      onKeyPress="if(this.value.length==9) return false;"
                      >
                      <span matSuffix>horas</span>
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="form-buttons-container">
                  <button mat-button matStepperPrevious color="secondary"  (click)="atras()">Atrás</button>
                  <button mat-button mat-raised-button matStepperNext (click)="confirmarVehiculo()" color="primary">Siguiente</button>
                </div> 
              </form>

            </mat-step>

            <mat-step [stepControl]="secondFormGroup">

              <ng-template matStepLabel>
                Seleccionar servicio
              </ng-template>
              <form [formGroup]="secondFormGroup">
              <!-- EDITANDO-->
              <div>
                <h2 class="form-title">Selecciona tu servicio</h2>
              </div> 
              <div>
                <mat-radio-group  class="services-container"  aria-label="Servicio" formControlName="servicioSeleccionado">
                
                  <div *ngFor="let serv of newServices"
                  class="row-service" 
                  (click)="selectServicio(serv.codigo)"
                  [class.selected]="serv.codigo==secondFormGroup.get('servicioSeleccionado').value">  
                  <div class="service-image">
                    <img  [src]="'./assets/images/servicio-'+ serv.codigo +'.svg'">
                  </div>        
                  <div class="rd-description">
                    
                    <mat-radio-button  [value]="serv.codigo" [checked]="serv.codigo==secondFormGroup.get('servicioSeleccionado').value">{{ serv.nombre }}</mat-radio-button>             
                    
                    <div class="row-button-more" (click)="infoServicio(serv)">
                      SABER MÁS <button class="button-more">></button>
                    </div>
                  </div>  
                </div>
              </mat-radio-group>

              </div>
              


              <!--FIN-->
              <div  class="container-branch" *ngIf="regiones.length > 0">
                <div>
                  <h2 class="form-title">Selecciona tu sucursal</h2>
                </div>                
                <div class="form-responsive">
                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Ciudad</mat-label>
                      <mat-select
                        required
                        formControlName="servicioRegion"
                        (selectionChange)="onRegionSelect()"
                        >
                        <mat-option value="">Seleccione ciudad</mat-option>
                        <mat-option *ngFor="let reg of regiones" [value]="reg.Region">
                          {{ reg.NomRegion }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="form-responsive-child">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Sucursal</mat-label>
                      <mat-select
                        required
                        formControlName="servicioSucursal"
                        (selectionChange)="onSucursalSelect()"
                        >
                        <mat-option value="">Seleccione sucursal</mat-option>
                        <mat-option *ngFor="let suc of sucursales" [value]="suc.Sucursal">
                          {{ suc.NomSucursal | sucursal:firstFormGroup.get('vehiculoFamilia').value:secondFormGroup.value.servicioSeleccionado }}
                        </mat-option>
                      </mat-select>

                        
                    </mat-form-field>
                  </div>

                  <div class="form-responsive-child">
                    <p style="margin-top:10px" *ngIf="currentMapa">{{ currentMapa.mapa.direccion }}<br>
                      <span (click)="openDialog(currentMapa.sucursal,currentMapa.mapa.direccion,currentMapa.mapa.mapa)" style="color:#0066CC;text-decoration:underline;font-size:11px;cursor:pointer">Ver mapa</span>
                    </p>
                  </div>

                </div>
              </div>

                <div class="form-buttons-container">
                  <button mat-button matStepperPrevious color="secondary">Atrás</button>
                  <button mat-button mat-raised-button matStepperNext (click)="confirmarServicio()" color="primary">Siguiente</button>
                </div>
              </form>
              <div >               
              </div>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup">

                <ng-template matStepLabel>
                  Seleccionar horario
                </ng-template>

                <form [formGroup]="thirdFormGroup">

                  <div>
                    <h2 class="form-title">Seleccionar fecha y hora para agendar tu visita</h2>
                  </div>

                  <div class="form-responsive">

                   

                   <div class="form-responsive-child asesores-select">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Asesor</mat-label>
                        <mat-select
                          formControlName="agendaAsesor"
                          (selectionChange)="onAsesorSelect()"
                          >
                          <mat-option value="">Todos los asesores</mat-option>
                          <mat-option *ngFor="let asesor of asesores" [value]="asesor.Asesor">
                            {{ asesor.NomAsesor }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field  class="example-full-width calendar-mobile" appearance="outline">
                        <mat-label>Fecha</mat-label>
                        <input matInput 
                          [matDatepicker]="picker" 
                          [value]="selectedDate"
                          [min]="minDate"
                          (dateChange)="selectDateMobile($event)"
                          readonly
                          (click)="picker.open()"
                          >
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
                      </mat-form-field>

                      <mat-calendar class="calendar-desktop" [(selected)]="selectedDate" (selectedChange)="selectDate($event)" [minDate]="minDate"></mat-calendar>

                    </div>

                    <div class="form-responsive-child">
                      <p *ngIf="horariosArray.length > 0" style="margin-top:5px;"><b>Seleccione un horario disponible:</b></p>
                      <mat-radio-group  class="horario-group" *ngIf="horariosArray.length > 0" formControlName="agendaHorario" aria-label="Seleccione un horario">
                        <span *ngFor="let horario of horariosArray;let j = index">
                          <mat-radio-button 
                            class="horario" 
                            [value]="horario.Time"
                            (click)="selectHorario(horario.Time)"
                            labelPosition="after">
                            {{ horario.Format }}</mat-radio-button>
                        </span>
                      </mat-radio-group>

                      <div class="form-responsive-child">
                        <div class="horarioResume" *ngIf="thirdFormGroup.get('agendaAsesor').value != '' && thirdFormGroup.get('agendaHorario').value != ''">
                          <div class="row-horarioResume">
                            <img src="./assets/images/ico-taller.png" style="margin-right: 10px;">
                            <p><b>Fecha y hora de su cita:</b> {{ resume.fecha }} {{ resume.hora }}</p>
                          </div>
                          <div class="row-horarioResume">
                            <img src="./assets/images/person-circle-outline.png" style="margin-right: 10px;"> 
                            <p><b>Asesor: </b> {{ resume.asesor }}</p>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="proximaFechaDisponible !== '' && horariosArray.length == 0">
                        <p style="text-align:center">No hay horarios disponibles en la fecha seleccionada. Próxima fecha con disponibilidad: <b>{{ proximaFechaDisponible}}</b></p>
                      </div>
                    </div>

                    <div class="form-responsive-child">

                    </div>               


                  </div>
                  <div class="form-buttons-container">
                    <button mat-button matStepperPrevious color="secondary">Atrás</button>
                    <button mat-button mat-raised-button matStepperNext (click)="confirmarHorario()" color="primary">Siguiente</button>
                  </div>
                </form>
              </mat-step>
            <mat-step [stepControl]="fourthFormGroup">
              <ng-template matStepLabel>
                Confirmar
              </ng-template>

              <div class="parent">
                <div class="detail">
                  <h2 class="form-title-special">Detalle de tu reserva</h2>
                  <div class="resume">
              
                    <p matLine>
                      <b>Servicio: </b><span> {{ resume.servicio }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Vehículo: </b> <span> {{ resume.modelo }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Placa: </b> <span> {{ resume.placa }}</span>
                    </p>
                    
                    <p matLine>
                      <b>Fecha: </b> <span> {{ resume.fecha }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Hora: </b> <span> {{ resume.hora }}</span>
                    </p>

                    <p matLine>
                      <b>Sucursal:</b><span> {{ resume.sucursal | sucursal:firstFormGroup.get('vehiculoFamilia').value:secondFormGroup.value.servicioSeleccionado }}</span>
                    </p> 
                 
                    <p matLine>
                      <b>Asesor: </b> <span> {{ resume.asesor }}</span>
                    </p>
                 
                    <p matLine *ngIf="resume.telefono">
                      <b>Teléfono: </b> <span> {{ resume.telefono }}</span>
                    </p>
                 
                    <p matLine *ngIf="resume.telefono">
                      <b>Email:</b><span> {{ resume.email }}</span>
                    </p>
      
                                   
              </div>
                </div>
                <div class="form">
                  <h2 class="form-title-special">Datos de contacto</h2>
                  <form [formGroup]="fourthFormGroup" >
                    <div class="parent-form mobile-size">

                      <div class="parent-form-child " >
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>DNI/CE</mat-label>
                          <input 
                          required
                          matInput
                          type="text"
                          (input)="onDocumentChange($event)"
                          formControlName="clienteDocumento"
                          maxlength="10"
                          >
                        </mat-form-field>
                      </div>

                      <div class="parent-form-child" *ngIf="clienteValido">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Nombre</mat-label>
                          <input matInput
                          required
                          formControlName="clienteNombre"
                          maxlength="32"
                          >
                        </mat-form-field>
                      </div>
                      <div class="parent-form-child"  *ngIf="clienteValido">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Apellido</mat-label>
                          <input matInput
                          required
                          formControlName="clienteApellido"
                          maxlength="32"
                          >
                        </mat-form-field>  
                      </div>
                      
                      <div class="parent-form-child"  *ngIf="clienteValido">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Teléfono</mat-label>
                          <input matInput
                          required
                          type="text"
                          formControlName="clienteTelefono"
                          onKeyPress="if(this.value.length==9) return false;"
                          >
                        </mat-form-field>
                      </div>
                      <div class="parent-form-child"  *ngIf="clienteValido">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Email</mat-label>
                          <input 
                          matInput
                          required
                          formControlName="clienteEmail">
                        </mat-form-field>
                      </div>
                      <div class="parent-form-child"  *ngIf="clienteValido">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Observaciones</mat-label>
                          <input matInput
                          formControlName="clienteObservaciones"
                          maxlength="64"
                          >
                        </mat-form-field>
                      </div>

                      <div class="parent-form-child full-width">
                        <mat-checkbox color="primary" formControlName="clienteGDPR1">He leído la <a href="https://www.divemotor.com/politicas-de-privacidad" target="_blank" style="color:#ff6e00">Autorización</a> y acepto el tratamiento de mis datos personales a DIVEMOTOR.</mat-checkbox>
                      </div>
                      <div class="parent-form-child full-width">
                        <mat-checkbox color="primary" formControlName="clienteGDPR2">Acepto el envío de promociones.</mat-checkbox>
                      </div>
                      </div>
                  </form>
                </div>
              </div>
              <div class="form-buttons-container">
                <button mat-button matStepperPrevious color="secondary">Atrás</button>
                <button mat-button mat-raised-button (click)="confirmarAgenda()" class="agendar-button" color="primary">Agendar</button>
              </div>

            </mat-step>

            <mat-step class="hidden-step">
              <ng-template matStepLabel>
                Detalle
              </ng-template>

              <div class="parent">
                <div class="message">
                  <h2 class="form-title-special">Su cita ha sido agendada</h2>
                  <p matLine>El código de su reserva es <span class="booking-code" [attr.data-message]="resume.status">{{ resume.codigo }}</span></p>
                  <p matLine>Pronto recibirás un correo electrónco con el detalle de tu solicitud.</p>
                </div>
                <div class="detail">
                  <h2 class="form-title-special">Detalle de tu reserva</h2>
                  <div class="resume">
              
                    <p matLine>
                      <b>Servicio: </b><span> {{ resume.servicio }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Vehículo: </b> <span> {{ resume.modelo }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Placa: </b> <span> {{ resume.placa }}</span>
                    </p>
                    
                    <p matLine>
                      <b>Fecha: </b> <span> {{ resume.fecha }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Hora: </b> <span> {{ resume.hora }}</span>
                    </p>

                    <p matLine>
                      <b>Sucursal:</b><span> {{ resume.sucursal | sucursal:firstFormGroup.get('vehiculoFamilia').value:secondFormGroup.value.servicioSeleccionado }}</span>
                    </p> 
                 
                    <p matLine>
                      <b>Asesor: </b> <span> {{ resume.asesor }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Teléfono: </b> <span> {{ resume.telefono }}</span>
                    </p>
                 
                    <p matLine>
                      <b>Email:</b><span> {{ resume.email }}</span>
                    </p>             
              </div>
                </div>
                <div class="form">
                  <h2 class="form-title-special">Datos de contacto</h2>
                  <div class="resume">
              
                    <p matLine>
                      <b>Nombre: </b><span> {{ resume.clienteNombre }} {{ resume.clienteApellido }}</span>
                    </p>
                 
                    <p matLine>
                      <b>DNI/CE: </b> <span> {{ resume.clienteDocumento }}</span>
                    </p>
                 
                    <p matLine *ngIf="clienteValido">
                      <b>Teléfono: </b> <span> {{ resume.clienteTelefono }}</span>
                    </p>
                    
                    <p matLine *ngIf="clienteValido">
                      <b>E-mail: </b> <span> {{ resume.clienteEmail }}</span>
                    </p>
                 
                    <p matLine *ngIf="resume.clienteObservaciones">
                      <b>Observación: </b> <span> {{ resume.clienteObservaciones }}</span>
                    </p>
            
              </div>
                </div>
              </div>
              <div class="form-buttons-container">
                <button mat-button class="go-to-inicio" [routerLink]="'/inicio'" color="secondary">Volver al Inicio</button>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        
    </mat-card>
</div>
