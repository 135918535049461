<div>
    <label id="example-radio-group-label"><b>Seleccione un asesor:</b></label>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="asesorConfirmado">
      <mat-radio-button class="example-radio-button" *ngFor="let asesor of data.asesores" [value]="asesor.codigo">
        {{asesor.nombre}}
      </mat-radio-button>
    </mat-radio-group>
</div>
<hr>
<div mat-dialog-actions>
  <button class="button-close" mat-button (click)="onNoClick()">Cerrar</button>
  <button class="button-confirmar" mat-button mat-raised-button [mat-dialog-close]="asesorConfirmado" cdkFocusInitial>Confirmar</button>
</div>