    <div style="height: 0;"> 
        <div class="popup-close-header">
            <span class="popup-close" (click)="onNoClick()">X</span>
        </div>
        <p class="question" style="margin: 10px 0px;">¿Desea anular su reserva <b>{{codigo}}</b>?</p>

        <div >
            <button class="button-yes" mat-button 
            mat-raised-button 
            [mat-dialog-close]="true">Si</button>
            <br>
            <br>
            <button class="butoon-not" mat-button 
            mat-raised-button 
            [mat-dialog-close]="false">No</button>
        </div>
    </div>
