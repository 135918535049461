import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-anulacion',
  templateUrl: './popup-anulacion.component.html',
  styleUrls: ['./popup-anulacion.component.scss']
})
export class PopupAnulacionComponent implements OnInit {

  codigo: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PopupAnulacionComponent>) { }

  ngOnInit(): void {
    this.codigo = this.data.codigo;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
