export const environment = {
  production: false,
  firebase: {
    projectId: 'agendamiento-divemotor',
    appId: '1:527271687043:web:8016a0e24c6ea96d4afcb6',
    storageBucket: 'agendamiento-divemotor.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyA2rBedQ_u-YHf6i3_xiLrxJcl-PvkWmWE',
    authDomain: 'agendamiento-divemotor.firebaseapp.com',
    messagingSenderId: '527271687043',
  },
  collectionAds: 'qa-ads',
  collectionSucursales: 'qa-sucursales',
  modelosEndPoint:"https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/ModelosSet",
  agendaEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/AgendaConfigBaseSet',
  agendaEndPointV2:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/AgendaConfigBaseV2Set',
  promocionesEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/posventa/v1/promociones/PromocionesWebSet',
  movilidadEndpoint:'https://apimazqa.grupokaufmann.com/qas/erp/cliente/v1/movilidad/Retorna_unidadesSet',
  calendarioEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agendaweb/calendario/SucursalCalendarSet',
  confirmacionEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/AgendaConfirmaSet',
  reagendaEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/ReagendaSet',
  retornaReservaEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/cliente/v1/movilidad/Retorna_reservasSet',
  retornaReservaEndPointV2:'https://apimazqa.grupokaufmann.com/qas/erp/cliente/v1/movilidad/Retorna_reservas_v2Set',
  anulaReservaEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/AgendaAnulaSet',
  listaSetEndPoint:'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/ListaSet',
  datosContacto: 'https://apimazqa.grupokaufmann.com/qas/erp/servicio/v1/agenda/DatosContactoSet',
  apiKey: '13fa10e542d844388f0b72b21e5c1246',
  smsEndpoint: 'https://apimazqa.grupokaufmann.com/qas/servicio/v1/mensaje',
  smsApi: 'BKX6GY3bRArtYYwPPtUNR5rGublHLWyy',
  instrumentationKey: '55d4ed5d-b972-47a6-b583-e4deceb6b161',
  CitaEndpoint: 'https://apimazqa.grupokaufmann.com/qas/servicio/v1/movilidad/agenda/cita',
  CitaApi: 'BKX6GY3bRArtYYwPPtUNR5rGublHLWyy',
  SiteID: 'PE01',
};
