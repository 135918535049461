import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sucursal'
})
export class SucursalPipe implements PipeTransform {

  transform(value: string, tipo: string, servicio: string): unknown {

    var sucursal = value;
    if(value.includes('Canadá')){

      if(['1','2'].includes(tipo) && servicio != '4'){
        sucursal = 'Sucursal Arriola';
      }

      if(['1','2'].includes(tipo) && servicio == '4'){
        sucursal = 'Sucursal Bussalleu';
      }
      
    }

    return sucursal;
  }

}
