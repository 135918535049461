<div>
    <div class="popup-close-header">
        <span class="popup-close" (click)="onNoClick()">X</span>
    </div>
    <div class="row-title-servics">
        <img  [src]="'./assets/images/servicio-'+ data.codigo +'.svg'"> 
        <h4>{{ data.nombre }}</h4>
    </div>
    <hr>
    <p>{{ data.detalle }}</p>
</div>