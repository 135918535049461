import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RetornaReservaResponse } from 'src/app/shared/interfaces/retorna-reserva-response';
import { BookService } from 'src/app/shared/services/book.service';
import { ModelsService } from 'src/app/shared/services/models.service';
import { RecaptchaService } from 'src/app/shared/services/recaptcha.service';
import { PopupAnulacionComponent } from '../popup-anulacion/popup-anulacion.component';


declare let gtag: Function; 

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchFormGroup: FormGroup;
  reservaArray: Array<RetornaReservaResponse> = [];
  validRecaptcha = false;

  reagendar = false;

  //Resume Model
  resume = {
    empty: true,
    codigo: '',
    placa: '',
    hora: '',
    fecha:'',
    sucursal: '',
    asesor: '',
    nombre: '',
  }

  aceptaAnular: any;

  constructor(    
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    public bookService: BookService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public loader: NgxUiLoaderService,
    public recaptchaService: RecaptchaService,     
    ) {

    this.searchFormGroup = this._formBuilder.group({
      placa: ['', Validators.compose([Validators.maxLength(6)])],
      dni: ['', Validators.compose([Validators.maxLength(12)])],
      codigoReserva: ['', Validators.compose([Validators.maxLength(10)])],
    });

   }

  ngOnInit(): void {
    if(this.route.snapshot.params['codigo'] !== undefined){
      if(this.route.snapshot.params['codigo'] == 'reagendar'){
        this.reagendar = true;
      }
      else{
        this.searchFormGroup.get('codigoReserva').setValue(this.route.snapshot.params['codigo']);
        this.searchBook();
      }
      
    }     
  }
  resolved(captchaResponse: string) {
    this.recaptchaService.validateRecaptcha(captchaResponse).subscribe((data) => {
       if(data.success) {
        this.validRecaptcha = true;
       }
       else{
        this.validRecaptcha = false;
       }
    });
  }
  
  searchBook() {
    if(!this.validRecaptcha) {
      this._snackBar.open(
        'Debe completar el captcha',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
      return;
    }

    const placa = this.searchFormGroup.value.placa;
    const dni = this.searchFormGroup.value.dni;
    const codReserva = this.searchFormGroup.value.codigoReserva;

    //Clear current search
    this.reservaArray = [];
    this.resume.empty = true;

    this.loader.start('searck-book');
    this.bookService
      .getReserva(dni, placa, codReserva,'PE01')
      .subscribe((data) => {
        this.reservaArray = data;
        if (this.reservaArray.length === 0) {
          this.bookService
            .getReserva(dni, placa, codReserva,'PE02')
            .subscribe((data) => {
              this.reservaArray = data;
              this.loader.stop('searck-book');
              this.validateBook();
            });
        }
        else{
          this.loader.stop('searck-book');
          this.validateBook();
        }
       
      });
      
  }

  validateBook(){
    if (this.reservaArray.length === 0) {
      this._snackBar.open(
        'No se encontraron registros de una reserva con esos datos',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    } else {

      if(this.reagendar){
        this.router.navigate(['/reagendar-servicio/' + this.reservaArray[0].CodReserva + '/' + this.reservaArray[0].Placa]);
      }
      else{
        this.resume.empty = false;
        this.resume.nombre = this.reservaArray[0].NomCuenta;
        this.resume.placa = this.reservaArray[0].Placa;

        let hora = this.reservaArray[0].Hora;
        let horaFormat = hora.slice(2, -4).trim();
        this.resume.hora = horaFormat.replace('H', ':');

        let fecha = this.reservaArray[0].Fecha;
        this.resume.fecha = moment(fecha).format('DD/MM/YYYY');

        this.resume.sucursal = this.reservaArray[0].NomSucursal;
        this.resume.asesor = this.reservaArray[0].NomAsesor + ' ' + this.reservaArray[0].ApellAsesor;
        this.resume.codigo = this.reservaArray[0].CodReserva;
      }
      
    }
  }

  reagendarReserva(){
    this.router.navigate(['/reagendar-servicio/' + this.reservaArray[0].CodReserva + '/' + this.reservaArray[0].Placa]);
  }


  anularReserva() {
    let dialogRef = this.dialog.open(PopupAnulacionComponent, {
      data: {
        codigo: this.resume.codigo
      },
      autoFocus: false,
      width:'360px',
      height:'215px'
    })
    dialogRef.afterClosed().subscribe(result => {
      this.aceptaAnular = result;
      if (this.aceptaAnular === true) {

        this.loader.start('delete-book');
        this.bookService.anulaReserva(this.resume.codigo)
          .subscribe(data => {

            this.loader.stop('delete-book');
            
            if(data[0].Mensaje == 'Anulado'){

              //Event GA4
              gtag('event', 'delete-booking',{
                'codigo': this.resume.codigo,
              });

              this._snackBar.open(
                'Su reserva ha sido anulada exitosamente.',
                'X',
                {
                  duration: 4000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                }
              );


              this.router.navigate(['inicio']);
            }
            else{
              this._snackBar.open(
                'Ocurrio un error al realizar la anulación, intente nuevamente.',
                'X',
                {
                  duration: 4000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                }
              );
            }

            
          });
      }
    })
  }

}
