import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingComponent } from './components/booking/booking.component';
import { HomeComponent } from './components/home/home.component';
import { RebookingComponent } from './components/rebooking/rebooking.component';
import { SearchComponent } from './components/search/search.component';

const routes: Routes = [
  { path: 'inicio', component: HomeComponent },
  { path: 'inicio/:brand', component: HomeComponent },

  { path: 'agendar-servicio', component: BookingComponent },
  { path: 'reagendar-servicio/:codigo/:placa', component: RebookingComponent },

  { path: 'consultar-reserva', component: SearchComponent },
  
  {
    path: 'consultar-reserva/:codigo',
    component: SearchComponent,
  },

  {
    path: 'consultar-reserva/reagendar',
    component: SearchComponent,
  },

  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  {path: '**', redirectTo: '/inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
