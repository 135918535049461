import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  
  constructor(
    private httpClient: HttpClient,

  ) {       
   }

   public validateRecaptcha(response){
    let endpoint = "https://qa-recall.divemotor.com/api-recapcha-validate";

    return this.httpClient
      .post(endpoint, { "g-recaptcha-response" : response  })
      .pipe(
        map((data: any) => {
          return data;
        })
      );

  }


}
