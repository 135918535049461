import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-popup-map',
  templateUrl: './popup-map.component.html',
  styleUrls: ['./popup-map.component.scss']
})
export class PopupMapComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopupMapComponent>,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  mapUrl(){
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.mapa)
  }
  

}
