<div class="container-previus-surcursal">
    <div class="popup-close-header">
        <span class="popup-close" (click)="onNoClick()">X</span>
    </div>
    
    <h2 *ngIf="region && sucursal"><b>¿Desea ser atendido en la misma sucursal de su último servicio?</b></h2>
    <p *ngIf="region && sucursal">
        {{ region }} - {{ sucursal }}
    </p>
    
    <h2 *ngIf="asesor"><b>¿Desea ser atendido por el mismo asesor de su último servicio?</b></h2>
    <p *ngIf="asesor">
        {{ asesor }}
    </p>

    <h2 *ngIf="nombre"><b>Tenemos estos datos registrados:</b></h2>
    <p *ngIf="nombre">
        <b>Nombre y apellido:</b> {{ nombre }}<br>
        <b>Email:</b> {{ email }}<br>
        <b>Teléfono:</b> {{ telefono }}<br>
        <br><br>
        <b>¿Desea actualizar esta información?</b> A través de este email y teléfono nuestro asesores se comunicaran con usted.
    </p>
    <hr>
</div>

<mat-dialog-actions align="end"  *ngIf="region && sucursal">
    <button class="button-decline" mat-button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>Cambiar sucursal</button>
    <button class="button-accept" mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Misma sucursal</button>
</mat-dialog-actions>

<mat-dialog-actions align="end"  *ngIf="asesor">
    <button class="button-decline" mat-button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>Cambiar asesor</button>
    <button class="button-accept" mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Mismo asesor</button>
</mat-dialog-actions>

<mat-dialog-actions align="end"  *ngIf="nombre">
    <button class="button-decline" mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Si</button>
    <button class="button-accept" mat-button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>No</button>
</mat-dialog-actions>