import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { Vehiculo } from 'src/app/shared/interfaces/vehiculo';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ModelsService } from 'src/app/shared/services/models.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; 
import { ModeloVehiculo } from 'src/app/shared/interfaces/modelo-vehiculo';
import { environment } from 'src/environments/environment';
import { PopupMapComponent } from '../popup-map/popup-map.component';
import { PopupSelectManagerComponent } from '../popup-select-manager/popup-select-manager.component';
import { maps } from 'src/app/data/maps';
import { PopupPlacaNotFoundComponent } from '../popup-placa-not-found/popup-placa-not-found.component';
import { PopupServiceInfo } from '../popup-service-info/popup-service-info.component';
import { PopupSchedule } from '../popup-schedule/popup-schedule.component';
import { PopupPreviusData } from '../popup-previus-data/popup-previus-data.component';
import { BookService } from 'src/app/shared/services/book.service';
import { PopupPreviusSucursalAsesor } from '../popup-previus-sucursal-asesor/popup-previus-sucursal-asesor.component';
import { PopupDerive } from '../popup-derive/popup-derive.component';

declare let gtag: Function; 
declare let exponea: any; 


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

   @ViewChild('stepper') private stepper: MatStepper;

   //Validate Current Hour
   checkHour = 17;
   checkMinute = 0;
   checkDate = moment().hour(this.checkHour).minute(this.checkMinute).second(0);
   checkResult = moment().isAfter( this.checkDate);

    //Required Data to Step 1
    placaConsultada = false;
    clienteValido = false;
    mismoCliente = false;
    auxClientePhone = '';
    auxClienteEmail = '';

    currentVehicle: any = false;

    tiposVehiculo: any = [];

    marcaVehiculoResponse = [];
  
    getAllresponse = [];
    
    tipoVehiculoResponseOriginal = [];
    tipoVehiculoResponse: { tipo: string; codigo: string; }[] = [];
  
    modeloVehiculoResponseOriginal: Array<ModeloVehiculo> = [];
    modeloVehiculoResponse: Array<ModeloVehiculo> = [];
    
    allData : any = [];
    //Required Data to Step 2
    regiones  : any = [];
    sucursales  : any = [];
    servicios : any = [];
    promociones : any = [];

    newServices: any = [];
  
    //Required Data to Step 3
    asesores : any = [];
    horariosArray : any = [];
    proximaFechaDisponible: string = '';
  
    //FormGroups to Validate
    isLinear = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
  
    //Aux Models
    currentMapa: any = false;
    selectedDate: Moment = moment().add(1,'d');
    minDate : Moment = moment().add(1,'d');

    //Resume Model
    resume = {
      status : "",
      codigo: "",
      modelo:'Modelo',
      placa: 'Placa',
      servicio: 'Servicio Seleccionado',
      hora: 'Hora de agenda',
      fecha:'Fecha de agenda',
      sucursal: 'Sucursal',
      asesor: 'Asesor',
      telefono: 'Telefono Asesor',
      email: 'Email Asesor',
      clienteNombre: 'Cliente nombre',
      clienteApellido: 'Apellido',
      clienteDocumento: 'Cliente DNI',
      clienteTelefono: 'Cliente Telefono',
      clienteEmail: 'Cliente Email',
      clienteObservaciones: 'Cliente Observacion',
    }
  
    constructor(
      public configuracionAgenda: ConfigurationService,
      public modelosService: ModelsService,
      public loader: NgxUiLoaderService,
      public bookService: BookService,
      private router: Router, 
      public dialog: MatDialog,
      private _formBuilder: FormBuilder,
      private _snackBar: MatSnackBar,      
    ) { }
  
  
    async ngOnInit(): Promise<void> {

       //Cargando toda la data
       this.fillAll();
      
      //Cargando lista de modelos
      this.fillModelosVehiculo();

      this.firstFormGroup = this._formBuilder.group({
        vehiculoPlaca: ['', Validators.compose([Validators.required, Validators.maxLength(7), Validators.pattern('[a-zA-Z0-9]{3}[-]{0,1}[0-9]{3}')])],
        vehiculoFamilia: ['', Validators.required],
        vehiculoMarca: ['', Validators.required],
        vehiculoModelo: ['', Validators.required],
        vehiculoKilometraje: [''],
        vehiculoHorometro: [''],
      });
  
      this.secondFormGroup = this._formBuilder.group({
        servicioRegion: ['', Validators.required],
        servicioSucursal: ['', Validators.required],
        servicioSeleccionado: ['', Validators.required],
      });
      
      this.thirdFormGroup = this._formBuilder.group({
        agendaAsesor: ['', Validators.required],
        agendaFecha: [moment().add(1,'d'), Validators.required],
        agendaHorario: ['', Validators.required],
      });

  
      this.fourthFormGroup = this._formBuilder.group({
        clienteNombre: ['', Validators.required],
        clienteApellido: ['', Validators.required],
        clienteDocumento: ['', Validators.compose([Validators.required])],
        clienteEmail: ['', Validators.compose([Validators.required, Validators.email])],
        clienteTelefono: ['', Validators.required],
        clienteObservaciones: [''],
        clienteGDPR1: [false, Validators.requiredTrue],
        clienteGDPR2: [false, Validators.required],
      });


      //Verificar Horario
      this.verifySchedule();
  }

  verifySchedule(){

    if(this.checkResult){
      const dialogRef = this.dialog.open(PopupSchedule, {
        width: '550px',
      });
    }
  
  }


  async fillAll() {
    this.loader.start('fill-all');
    this.configuracionAgenda
      .getAll()
      .subscribe((data) => {
       this.getAllresponse = data;
       this.loader.stop('fill-all');
      });
  }

    //Step Change Aux
  public onStepChange(event: any): void {

    //Scroll top
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    let currentStep = event.selectedIndex;

    if(currentStep == 1){

      //Enviar identificacion de vehiculo a exponea
      let identifica_vh = {
        'item_patente': this.firstFormGroup.get('vehiculoPlaca').value,
        'tipo_vh': this.tipoVehiculoResponseOriginal.find((item => item.codigo == this.firstFormGroup.get('vehiculoFamilia').value)).tipo,
        'item_brand': this.marcaVehiculoResponse.find((item => item.codigo == this.firstFormGroup.get('vehiculoMarca').value)).marca,
        'item_modelo': this.firstFormGroup.get('vehiculoModelo').value,
        'kilometraje_actual': this.firstFormGroup.get('vehiculoKilometraje').value,
      }

      if(environment.production){
        exponea.track('agenda_servicio_identifica_vh', identifica_vh);
      }

    }

    if(currentStep == 2){

      this.validatePreviusAsesor(this.asesores);

      //Enviar Datos de Servicio a Exponea
      let selecciona_servicio = {
        'region_servicio': this.regiones.find((item => item.Region == this.secondFormGroup.get('servicioRegion').value)).NomRegion,
        'sucursal_servicio': this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value)).NomSucursal,
        'servicio_seleccionado': this.newServices.find((item => item.codigo == this.secondFormGroup.get('servicioSeleccionado').value)).nombre
      }
      if(environment.production){
        exponea.track('agenda_servicio_selecciona_servicio', selecciona_servicio );
      }
    
    }

    //Update Resume
    if(currentStep == 3){

      this.resume.modelo =  this.firstFormGroup.get('vehiculoModelo').value;
      this.resume.placa =  this.firstFormGroup.get('vehiculoPlaca').value;

      //Get Sucursal Name
      let oSucursal = this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value));
      this.resume.sucursal = oSucursal.NomSucursal;

      //Get Service Name
      let oServicio = this.newServices.find((item => item.codigo == this.secondFormGroup.get('servicioSeleccionado').value));
      this.resume.servicio = oServicio.nombre;

      //Geat Asesor Name
      let oAsesor = this.asesores.find((item => item.Asesor == this.thirdFormGroup.get('agendaAsesor').value));
      this.resume.asesor = oAsesor.NomAsesor;
      this.resume.email = oAsesor.Email;
      this.resume.telefono = oAsesor.TelefonoCellular;

      //Get Horario
      this.resume.fecha = moment(this.thirdFormGroup.get('agendaFecha').value.format("YYYYMMDD") + 'T' + this.thirdFormGroup.get('agendaHorario').value).format('DD/MM/YYYY');
      this.resume.hora = moment(this.thirdFormGroup.get('agendaFecha').value.format("YYYYMMDD") + 'T' + this.thirdFormGroup.get('agendaHorario').value).format('hh:mm a');


      if(environment.production){
        //Event GA4
        gtag('event', 'last-step',{
          'servicio': this.resume.servicio,
          'sucursal': this.resume.sucursal
        });

        //Enviar Evento a Exponea
        exponea.track('agenda_servicio_fecha', {
          'fecha_servicio': this.resume.fecha,
          'hora_servicio': this.resume.hora,
          'asesor_servicio':  this.resume.asesor
        });
      }

    }

    if(currentStep == 4){
      //Get Client data
      this.resume.clienteNombre =  this.fourthFormGroup.get('clienteNombre').value;
      this.resume.clienteApellido =  this.fourthFormGroup.get('clienteApellido').value;
      this.resume.clienteDocumento = this.fourthFormGroup.get('clienteDocumento').value;
      this.resume.clienteTelefono = this.fourthFormGroup.get('clienteTelefono').value;
      this.resume.clienteEmail = this.fourthFormGroup.get('clienteEmail').value;
      this.resume.clienteObservaciones = this.fourthFormGroup.get('clienteObservaciones').value;
    }

    
  }

  //OnKey Placa = 6 Digits
  onKey(event: any) {
    var placa = this.firstFormGroup.get('vehiculoPlaca').value;

    this.firstFormGroup.get('vehiculoPlaca').setValue(placa.toUpperCase());
    
    if ( !this.firstFormGroup.get('vehiculoPlaca').invalid) {
      this.onPatenteChanged( placa.replace('-', ''));
    }
    else{
      if(placa.length > 6){
        this._snackBar.open(
          'Formato de placa incorrecto. Usar formato ABC123 o ABC-123',
          'X',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    }
  }

  onDocumentChange(event: any) {
    var documento = this.fourthFormGroup.get('clienteDocumento').value;
    
    if ( !this.fourthFormGroup.get('clienteDocumento').invalid) {
      if(documento.length == 8){

        if(this.currentVehicle.RutClientePA == documento){

          this.loader.start();

          this.configuracionAgenda.getContact(this.currentVehicle.Placa).subscribe({
            next: (data) => {

              //Nombre
              this.fourthFormGroup.get('clienteNombre').setValue(data.Name2);
              this.fourthFormGroup.get('clienteNombre').disable({ onlySelf: true });

              //Apellido
              this.fourthFormGroup.get('clienteApellido').setValue(data.Name3);
              this.fourthFormGroup.get('clienteApellido').disable({ onlySelf: true });

              //Telefono
              this.auxClientePhone = data.Telf1;
              this.fourthFormGroup.get('clienteTelefono').setValue( data.Telf1);
              this.fourthFormGroup.get('clienteTelefono').disable({ onlySelf: true });

              //Email
              this.auxClienteEmail = data.SmtpAddr;
              this.fourthFormGroup.get('clienteEmail').setValue(data.SmtpAddr);
              this.fourthFormGroup.get('clienteEmail').disable({ onlySelf: true });

              this.clienteValido = false;
              this.mismoCliente = true;

              this.loader.stop();


              let dialogRef = this.dialog.open(PopupPreviusSucursalAsesor, {
                width: '550px',
                autoFocus: false,
                data: { 
                  nombre: data.Name1,
                  email: 'xxxxxxx' + String(data.SmtpAddr).slice(7),
                  telefono: 'xxxxx' +  data.Telf1.slice(5) }
              });
      
              dialogRef.afterClosed().subscribe(result => {
                  if(result === true){
                    this.clienteValido = true;
                    //Enable Contact Edit
                    //Telefono
                    this.fourthFormGroup.get('clienteTelefono').setValue('');
                    this.fourthFormGroup.get('clienteTelefono').enable({ onlySelf: true });

                    //Email
                    this.fourthFormGroup.get('clienteEmail').setValue('');
                    this.fourthFormGroup.get('clienteEmail').enable({ onlySelf: true });
                  }
              });

            },
            error: (err) => {
              this.loader.stop();
              console.log(err);
            }
          });

        }
        else{

          //Nombre
          this.fourthFormGroup.get('clienteNombre').setValue('');
          this.fourthFormGroup.get('clienteNombre').enable({ onlySelf: true });

          //Apellido
          this.fourthFormGroup.get('clienteApellido').setValue('');
          this.fourthFormGroup.get('clienteApellido').enable({ onlySelf: true });

          //Telefono
          this.fourthFormGroup.get('clienteTelefono').setValue('');
          this.fourthFormGroup.get('clienteTelefono').enable({ onlySelf: true });

          //Email
          this.fourthFormGroup.get('clienteEmail').setValue('');
          this.fourthFormGroup.get('clienteEmail').enable({ onlySelf: true });
          
          this.clienteValido = true;
          this.mismoCliente = false;
          this.auxClientePhone = '';
          this.auxClienteEmail = '';

          this._snackBar.open(
            'El documento no es el asignado al vehículo. Por favor ingrese sus datos de contacto.',
            'X',
            {
              duration: 8000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }
          );
          
        }
        
      }
    }
    else{
      if(documento.length > 9){
        this._snackBar.open(
          'Formato de documento incorrecto. Indique DNI o CE',
          'X',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    }
  }

  clearPlacaField(autoclear = true) {
    if(autoclear){
      this.firstFormGroup.get('vehiculoPlaca').setValue('');
    }
    this.firstFormGroup.get('vehiculoMarca').setValue('');
    this.firstFormGroup.get('vehiculoFamilia').setValue('');
    this.firstFormGroup.get('vehiculoModelo').setValue('');

    this.firstFormGroup.get('vehiculoMarca').enable();
    this.firstFormGroup.get('vehiculoFamilia').enable();
    this.firstFormGroup.get('vehiculoModelo').enable();

    this.tipoVehiculoResponse = [];
    this.modeloVehiculoResponse = [];
  }

  //OnChange Plca
  onPatenteChanged(value: string) {
    this.loader.start();
    this.modelosService.getDataVehiculo(value).subscribe((data: Vehiculo) => {

        if( data[0] !== undefined && data[0].TipoVehiculoServicio !== '' && data[0].TipoVehiculoServicio !== undefined ){
          let currentType = data[0].TipoVehiculoServicio;
          let currentMarca = data[0].CodigoMarca;
          let currentModelo = data[0].ModeloGenerico;

          //Cargando Marca
          this.firstFormGroup.get('vehiculoMarca').setValue(currentMarca);
          this.firstFormGroup.get('vehiculoMarca').disable({ onlySelf: true });
          this.onMarcaVehiculoSelected();
          
          //Cargando Tipo de Vehiculo
          this.firstFormGroup.get('vehiculoFamilia').setValue(currentType);
          this.firstFormGroup.get('vehiculoFamilia').disable({ onlySelf: true });
          this.onTipoVehiculoSelected();

          //Cargando Modelo
          if( this.modeloVehiculoResponse.filter(e => e.Mcodesd == currentModelo).length > 0){
            this.firstFormGroup.get('vehiculoModelo').setValue(currentModelo);
            this.firstFormGroup.get('vehiculoModelo').disable({ onlySelf: true });
          }
          

          //Cargando Vehiculo actual
          this.currentVehicle = data[0];

          //Validando Reserva Previa
          this.getPreviusData(value);
        }
        else{

          //Get Current Brand
          let currentBrand = localStorage.getItem('brand');
          if(currentBrand != 'divemotor'){
            let brand = this.marcaVehiculoResponse.find(e => e.slug == currentBrand);
            this.firstFormGroup.get('vehiculoMarca').setValue(brand.codigo);
            this.tipoVehiculoResponse = this.tipoVehiculoResponseOriginal.filter((item => brand.tipos.includes(item.codigo)));
          }
          else{
            this.firstFormGroup.get('vehiculoMarca').setValue('');
            this.firstFormGroup.get('vehiculoFamilia').setValue('');

            this.tipoVehiculoResponse = [];
            this.modeloVehiculoResponse = [];
          }

          this.firstFormGroup.get('vehiculoModelo').setValue('');

          const dialogRef = this.dialog.open(PopupPlacaNotFoundComponent, {
            width: '450px',
          });
        }

        this.placaConsultada = true;
        this.loader.stop();
    });

  }


  getPreviusData(placa){

    this.bookService.getReservaV2(placa).subscribe({
      next: (data) => {
        
        if(data.length > 0){
          const dialogRef = this.dialog.open(PopupPreviusData, {
            width: '550px',
            data: { reserva: data[0]}
          });
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
    
  }


  //OnChange Marca
  onMarcaVehiculoSelected() {
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    //Reset Next Steps to Avoid Conflict
    this.firstFormGroup.get('vehiculoFamilia').setValue('');
    this.firstFormGroup.get('vehiculoModelo').setValue('');
    this.secondFormGroup.reset({
        servicioRegion: '',
        servicioSucursal: '',
        servicioSeleccionado: '',
    });
    
    let brand = this.marcaVehiculoResponse.find(e => e.codigo == idMarca);
    this.tipoVehiculoResponse = this.tipoVehiculoResponseOriginal.filter((item => brand.tipos.includes(item.codigo)));

  }

  //OnChange Tipo
  onTipoVehiculoSelected() {

    //Reset Next Steps to Avoid Conflict
    this.firstFormGroup.get('vehiculoModelo').setValue('');
    this.secondFormGroup.reset({
      servicioRegion: '',
      servicioSucursal: '',
      servicioSeleccionado: '',
    });

    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;


    this.modeloVehiculoResponse = this.modeloVehiculoResponseOriginal.filter((item => item.MarcaId === idMarca && item.TipvehId === idTipo));

    //Cargar Servicios
    this.fillServicios(idTipo, idMarca);


  }

  //Cargando Modelos de vehiculos
  fillModelosVehiculo() {
    this.loader.start('fill-modelos');
    this.modelosService
      .getModelos()
      .subscribe((data) => {
        this.loader.stop('fill-modelos');
        this.modeloVehiculoResponseOriginal = data;
        //Cargando Marcas y Tipos
        this.fillMarcasVehiculo(data);
        this.fillTiposVehiculo(data);
        
      });
  }

  //Cargando Marcas de vehiculos
  fillMarcasVehiculo(modelos) {
    this.marcaVehiculoResponse =  this.modelosService.getMarcas(modelos);
  }





  //Cargando Tipos de vehiculos
  fillTiposVehiculo(modelos) {
    this.tipoVehiculoResponseOriginal =  this.modelosService.getTipos(modelos);
  }

  //Cargando Regiones
  fillRegiones(tipo, marca, servicio) {
    
    //Reset Next Steps to Avoid Conflict
    this.secondFormGroup.get('servicioSucursal').setValue('');

    //Cargar regiones
    this.regiones = _.chain(this.getAllresponse)
    .filter(region => region.TipoVehiculo === tipo && region.Marca === marca && region.Servicio === servicio)
    .uniqBy('Region')
    .sort((a, b) =>  a.NomRegion.localeCompare(b.NomRegion))
    .value();

    this.regiones = [...this.regiones.filter(r => r.Region == 15), ...this.regiones.filter(r => r.Region != 15)];


    //Verificar Sucursal Anterior
    if(this.currentVehicle.AsesorSucursal != ''){
      console.log('Comprobando sucursal anterior:' + this.currentVehicle.AsesorSucursal);
      const psucursales = _.chain(this.getAllresponse)
      .filter(region => region.TipoVehiculo === tipo && region.Marca === marca && region.Servicio === servicio && region.Sucursal === this.currentVehicle.AsesorSucursal ).value();
      
      if(psucursales.length > 0) {
        
        let dialogRef = this.dialog.open(PopupPreviusSucursalAsesor, {
          width: '550px', 
          autoFocus: false,
          data: { sucursal:  psucursales[0].NomSucursal , region: psucursales[0].NomRegion }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result === true){
            //Selecting Region
            this.secondFormGroup.get('servicioRegion').setValue( psucursales[0].Region );
            this.onRegionSelect();

            //Selecting Sucursal
             this.secondFormGroup.get('servicioSucursal').setValue( psucursales[0].Sucursal );
             this.onSucursalSelect();
          }
        });

      }
    
    }

  }

  //OnChance Region
  onRegionSelect() {
    this.servicios = [];
    this.promociones = [];

    //Reset Next Steps to Avoid Conflict
    this.currentMapa = false;
    this.secondFormGroup.get('servicioSucursal').setValue('');


    this.fillSucursales();
  }

  //Cargando Sucursales
  fillSucursales() {
    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;
    var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;
    var idRegion = this.secondFormGroup.get('servicioRegion').value;
    

    this.sucursales = _.chain(this.getAllresponse)
    .filter(sucursal => sucursal.TipoVehiculo === idTipo && sucursal.Marca === idMarca && sucursal.Servicio === idServicio && sucursal.Region === idRegion)
    .uniqBy('Sucursal')
    .sort((a, b) => a.NomSucursal.localeCompare(b.NomSucursal))
    .value();

    this.auxOnlyOneSucursal();
  }

  openDialog(sucursal: string,direccion: string,mapa: string): void {
    const dialogRef = this.dialog.open(PopupMapComponent, {
      width: '450px',
      data: {sucursal: sucursal, direccion: direccion, mapa:  mapa},
    });
  }


  auxOnlyOneSucursal(){
    if (this.sucursales.length == 1) {
      this.secondFormGroup.get('servicioSucursal').setValue( this.sucursales[0].Sucursal);

      //Update map link
      var idSucursal = this.secondFormGroup.get('servicioSucursal').value;


      
      var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
      var idMarca = this.firstFormGroup.get('vehiculoMarca').value;
      var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;
      var combinacion = idServicio + '-' + idTipo + '-' + idMarca;

      
      const currenSucursalName = this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value)).NomSucursal;
      var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.includes( combinacion)));
      if(cm == undefined){
        var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.length == 0));
      }

      this.currentMapa = {
        sucursal: currenSucursalName,
        mapa: cm
      };


      //Cargar Asesores
      this.fillAsesores( this.secondFormGroup.get('servicioSeleccionado').value);

    }
  }


  //Al cambiar Sucursal
  onSucursalSelect() {

    //Reset Next Steps to Avoid Conflict
    var idSucursal = this.secondFormGroup.get('servicioSucursal').value;

    console.log(idSucursal);

    if(idSucursal){

      var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
      var idMarca = this.firstFormGroup.get('vehiculoMarca').value;
      var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;

      var combinacion = idServicio + '-' + idTipo + '-' + idMarca;

      const currenSucursalName = this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value)).NomSucursal;
      var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.includes( combinacion)));
      if(cm == undefined){
        var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.length == 0));
      }
      
      this.currentMapa = {
        sucursal: currenSucursalName,
        mapa: cm
      };

      if(idSucursal == '3158'){
          const dialogRef = this.dialog.open(PopupDerive, {
            width: '450px',
          });
      }


      //Cargar Asesores
      this.fillAsesores( this.secondFormGroup.get('servicioSeleccionado').value);
    
    }
    else{
      this.currentMapa = false;
    }


  }



  //Cargando Servicios
  fillServicios(tipo, marca) {

    const servicios = _.chain(this.getAllresponse)
    .filter(s => s.TipoVehiculo === tipo && s.Marca === marca)
    .uniqBy('DescServicio')
    .map(s => ({
    'codigo': s.Servicio ,
    'nombre':s.DescServicio,
    'detalle': s.DetServicio
    }))
    .value();

    this.newServices = servicios;
  }


  //Seleccionar servicios
   selectServicio(idServicio){

    this.secondFormGroup.get('servicioSeleccionado').setValue(idServicio);
    
    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    //Reset Next Steps to Avoid Conflict
    this.secondFormGroup.get('servicioRegion').setValue('');
    this.secondFormGroup.get('servicioSucursal').setValue('');
    this.currentMapa = false;

    this.fillRegiones(idTipo,idMarca, idServicio);

  }

  //Info Servicios
  infoServicio(servicio){

    const dialogRef = this.dialog.open(PopupServiceInfo, {
      width: '450px',
      autoFocus: false,
      data: servicio,
    });

  }

  //Cargando Asesores
  fillAsesores(servicio) {

    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    var idRegion = this.secondFormGroup.get('servicioRegion').value;
    var idSucursal = this.secondFormGroup.get('servicioSucursal').value;

    this.configuracionAgenda
      .getAsesores(idTipo, idMarca, idRegion, idSucursal, servicio)
      .subscribe({
        next: (data) => {

          this.asesores = data;

        },
        error: (err) => {
          console.log( err );
        } 
      });
  }

  validatePreviusAsesor(data){

    if(data.length > 0) {
      //Verificar Asesor Anterior
      if(this.currentVehicle.AsesorUltimo != ''){
        console.log('Comprobando asesor anterior:' + this.currentVehicle.AsesorUltimo);

        let pasesores = _.chain(data)
        .filter(asesor => asesor.Asesor === this.currentVehicle.AsesorUltimo  ).value();

        if(pasesores.length > 0){

          let dialogRef = this.dialog.open(PopupPreviusSucursalAsesor, {
            width: '550px',
            autoFocus: false,
            data: { asesor:  pasesores[0].NomAsesor }
          });
  
          dialogRef.afterClosed().subscribe(result => {
              if(result === true){
                 //Selecting Asesor
                this.thirdFormGroup.get('agendaAsesor').setValue( pasesores[0].Asesor );
                this.onAsesorSelect();
              }
          });
        }

        
        
    
      }
    }
  }


  //Seleccionar Asesor
  onAsesorSelect(): void{
    //Clear current values
    this.thirdFormGroup.get('agendaHorario').setValue('');
    this.proximaFechaDisponible = '';
    this.horariosArray = [];

    this.fillCalendario(true);
  }


  //Seleccionar Fecha
  selectDate(event): void{
    this.thirdFormGroup.get('agendaFecha').setValue(event);

    //Clear current values
    this.thirdFormGroup.get('agendaHorario').setValue('');
    this.proximaFechaDisponible = '';
    this.horariosArray = [];

    this.fillCalendario(true);
  }

  selectDateMobile(event): void{
    this.thirdFormGroup.get('agendaFecha').setValue(event.value);

    //Clear current values
    this.thirdFormGroup.get('agendaHorario').setValue('');
    this.proximaFechaDisponible = '';
    this.horariosArray = [];

    this.fillCalendario(true);
  }

  //Cargar horarios
  fillCalendario(load: boolean) {

    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    var idSucursal = this.secondFormGroup.get('servicioSucursal').value;
    var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;

    var idAsesor = this.thirdFormGroup.get('agendaAsesor').value;
    var fecha = this.thirdFormGroup.get('agendaFecha').value.format('YYYYMMDD');

    if (load) this.loader.start('fill-calendario');
    this.configuracionAgenda
      .getCalendarios(idSucursal, fecha, 1, idAsesor , idServicio , idMarca, idTipo)
      .subscribe((data) => {

        //Obtener proximaFechaDisponible
        if(data.length > 0) this.proximaFechaDisponible = moment(data[0].Date).format('DD/MM/YYYY');

        //Filtrando solo la fecha solicidata
        const filterdata = data.filter(data => data.Date == fecha);

        console.log(this.asesores);
        console.log(filterdata);
        
        const filterdata2 = _.chain(filterdata)
          .groupBy(e => e.Time)
          .map((value,key) => (
            {
              'Time': key,
              "Asesores": _.chain(value).map(e => ({'nombre': this.asesores.find((item => item.Asesor == e.Pernr ))?.NomAsesor ?? ('NON') , 'codigo' : e.Pernr})).value(),
              'Format': (key.substring(2, 0) + ':' + key.substring(4, 2)) }
            ))
          .orderBy('Time','asc')
          .value();

        this.horariosArray = filterdata2;
        if (load) this.loader.stop('fill-calendario');
      });
  }

  selectHorario(horario: string){
  
    if( this.thirdFormGroup.get('agendaAsesor').value == ''){
      const asesoresAvailable = this.horariosArray.find(h => h.Time == horario);

      const dialogRef = this.dialog.open(PopupSelectManagerComponent, {
        width: '450px',
        autoFocus: false,
        data: {asesores : asesoresAvailable.Asesores.filter(e => e.nombre != 'NON')},
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result !== undefined){
          this.thirdFormGroup.get('agendaAsesor').setValue(result);

          this,this.updateDate(horario);
        }
      });
    }
    else{
      this,this.updateDate(horario);
    }
    
  }

  updateDate(horario){
    //Geat Asesor Name
    let oAsesor = this.asesores.find((item => item.Asesor == this.thirdFormGroup.get('agendaAsesor').value));
    this.resume.asesor = oAsesor.NomAsesor;
    this.resume.email = oAsesor.Email;
    this.resume.telefono = oAsesor.TelefonoCellular;

    //Get Horario
    this.resume.fecha = moment(this.thirdFormGroup.get('agendaFecha').value.format("YYYYMMDD") + 'T' + horario ).format('DD/MM/YYYY');
    this.resume.hora = moment(this.thirdFormGroup.get('agendaFecha').value.format("YYYYMMDD") + 'T' + horario ).format('hh:mm a');

  }


  //Validar datos Vehiculo
  confirmarVehiculo(){

    if(!this.firstFormGroup.valid){
      this._snackBar.open(
        'Por favor ingrese todos los datos de su vehículo.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

  }


  //Validar servicio seleccionado
  confirmarServicio(){

    let currentService = this.secondFormGroup.get('servicioSeleccionado').value;

    if(currentService == '' || currentService == undefined){
      this._snackBar.open(
        'Por favor seleccione uno de los servicios disponibles.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

  }

  //Validar horario seleccionado
  confirmarHorario(){

    const currentHorario = this.thirdFormGroup.get('agendaHorario').value;
    const currentAsesor = this.thirdFormGroup.get('agendaAsesor').value;

    if(currentHorario == '' || currentHorario == undefined){
      this._snackBar.open(
        'Por favor seleccione un horario disponible.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
      return;
    }

    if( currentAsesor == '' || currentAsesor == undefined){
      this._snackBar.open(
        'Por favor seleccione un asesor disponible.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

  }


  confirmarAgenda(){

    //Visual validation
    this.fourthFormGroup.markAllAsTouched();

    //Confirm validation last step
    if(this.fourthFormGroup.valid){

    var reserva = {
      vehiculo: {
        placa: this.firstFormGroup.get('vehiculoPlaca').value.replace('-', ''),
        familia: this.firstFormGroup.get('vehiculoFamilia').value,
        marca: this.firstFormGroup.get('vehiculoMarca').value,
        modelo: this.firstFormGroup.get('vehiculoModelo').value,
        kilometraje: this.firstFormGroup.get('vehiculoKilometraje').value,
        horometro: this.firstFormGroup.get('vehiculoHorometro').value
      },
      servicio: {
        region: this.secondFormGroup.get('servicioRegion').value,
        sucursal: this.secondFormGroup.get('servicioSucursal').value,
        servicio: this.secondFormGroup.get('servicioSeleccionado').value
      },
      agenda:{
        asesor: this.thirdFormGroup.get('agendaAsesor').value,
        fecha: this.thirdFormGroup.get('agendaFecha').value.format('YYYYMMDD'),
        horario: this.thirdFormGroup.get('agendaHorario').value
      },
      cliente:{
        nombre: this.fourthFormGroup.get('clienteNombre').value,
        apellido: this.fourthFormGroup.get('clienteApellido').value,
        documento: this.fourthFormGroup.get('clienteDocumento').value,
        email: this.fourthFormGroup.get('clienteEmail').value,
        telefono: this.fourthFormGroup.get('clienteTelefono').value,
        observaciones: this.fourthFormGroup.get('clienteObservaciones').value
      }
    };



    this.loader.start('save-booking');
    this.configuracionAgenda
        .getConfirmAgenda(
          '1234',
          reserva.cliente.documento,
          '',
          reserva.vehiculo.placa,
          reserva.agenda.fecha,
          reserva.agenda.horario,
          reserva.servicio.sucursal,
          reserva.agenda.asesor,
          reserva.cliente.observaciones,
          '',
          '',
          '',//promociones,
          '',
          reserva.vehiculo.horometro,
          reserva.vehiculo.kilometraje,
          '',
          '',
          '',
          '',
          reserva.servicio.servicio,
          reserva.vehiculo.familia,
          reserva.vehiculo.marca,
          reserva.vehiculo.modelo,
          reserva.cliente.documento,
          reserva.cliente.nombre,
          reserva.cliente.apellido,
          reserva.cliente.email,
          reserva.cliente.telefono
        )
        .subscribe((datos) => {

          this.loader.stop('save-booking');
          
          if(datos[0].Mensaje == 'Ok'){

            //Event GA4
            gtag('event', 'booking',{
              'fecha': reserva.agenda.fecha,
              'hora': reserva.agenda.horario
            });

            //Update booking code
            this.resume.codigo = datos[0].ReservasMasterCode;
            this.resume.status = datos[0].Mensaje;

            this.stepper.next();
          }
          else{
            this._snackBar.open(
              'Ocurrio un error al realizar su reserva, por favor intente nuevamente.',
              'X',
              {
                duration: 4000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
          }

        }); 

    }
    else{
      this._snackBar.open(
        'Por favor ingrese todos sus datos para confirmar su reserva.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );

      if(!this.fourthFormGroup.get('clienteGDPR1').value){
        this._snackBar.open(
          'Debe aceptar nuestras políticas para el tratamiento de sus datos personales.',
          'X',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    }
      
  }


  atras() {
    this.router.navigate(['/inicio']);
  }


}
