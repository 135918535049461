import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingComponent } from './components/booking/booking.component';
import { HomeComponent } from './components/home/home.component';
import { SearchComponent } from './components/search/search.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";

//Material
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';


//Firebase
import { environment } from 'src/environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { PopupAnulacionComponent } from './components/popup-anulacion/popup-anulacion.component';
import { PopupAdsComponent } from './components/popup-ads/popup-ads.component';
import { PopupMapComponent } from './components/popup-map/popup-map.component';
import { PopupSelectManagerComponent } from './components/popup-select-manager/popup-select-manager.component';
import { PopupPlacaNotFoundComponent } from './components/popup-placa-not-found/popup-placa-not-found.component';
import { PopupSchedule } from './components/popup-schedule/popup-schedule.component';
import { PopupPreviusData } from './components/popup-previus-data/popup-previus-data.component';
import { PopupPreviusSucursalAsesor } from './components/popup-previus-sucursal-asesor/popup-previus-sucursal-asesor.component';
import { PopupServiceInfo } from './components/popup-service-info/popup-service-info.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { RecaptchaModule } from 'ng-recaptcha';
import { TextMaskModule } from 'angular2-text-mask';
import { RebookingComponent } from './components/rebooking/rebooking.component';
import { PopupCurrentBook } from './components/popup-current-book/popup-current-book.component';
import { SucursalPipe } from './shared/pipes/sucursal.pipe';
import { PopupDerive } from './components/popup-derive/popup-derive.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#ff6e00",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 0,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#ff6e00",
  "fgsPosition": "center-center",
  "fgsSize": 70,
  "fgsType": "fading-circle",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(255,255,255,0)",
  "pbColor": "#ff6e00",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}


@NgModule({
  declarations: [
    AppComponent,
    BookingComponent,
    RebookingComponent,
    HomeComponent,
    SearchComponent,
    PopupAnulacionComponent,
    PopupAdsComponent,
    PopupMapComponent,
    PopupSelectManagerComponent,
    PopupPlacaNotFoundComponent,
    PopupServiceInfo,
    PopupSchedule,
    PopupDerive,
    PopupPreviusData,
    PopupCurrentBook,
    PopupPreviusSucursalAsesor,
    SucursalPipe,
  ],
  imports: [
    HttpClientModule,
    RecaptchaModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    TextMaskModule
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { panelClass: ['white-snackbar'] },
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
