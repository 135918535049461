import { Component } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { banners } from 'src/app/data/banners';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Reservas Divemotor';
  
  brand = 'divemotor';
  logotype = 'png';

  globalbg = banners['mercedes-benz'][0].path;


  constructor(
    private _router: Router,
  ) {
    if(environment.production){
      this.setUpAnalytics();
    }
  }

  ngOnInit() {
    this._router.events.subscribe((routerData) => {
      if(routerData instanceof ResolveEnd){ 

        if(routerData.url.includes('inicio')){
          switch(routerData.url){
            case '/inicio':
              let currentBrand = localStorage.getItem('brand');
              if(currentBrand == undefined){
                this.brand = 'divemotor';
                localStorage.setItem('brand', 'divemotor');
              }
              else{
                this.brand = currentBrand;
              }
            break;
            case '/inicio/divemotor':
              this.brand = 'divemotor';
              localStorage.setItem('brand', 'divemotor');
            break;
            case '/inicio/fiat':
              this.brand = 'fiat';
              localStorage.setItem('brand', 'fiat');
            break;
            case '/inicio/jeep':
              this.brand = 'jeep';
              localStorage.setItem('brand', 'jeep');
            break;
            case '/inicio/ram':
              this.brand = 'ram';
              localStorage.setItem('brand', 'ram');
            break;
            case '/inicio/dodge':
              this.brand = 'dodge';
              localStorage.setItem('brand', 'dodge');
            break;
            case '/inicio/mercedes-benz':
              this.brand = 'mercedes-benz';
              localStorage.setItem('brand', 'mercedes-benz');
            break;
            case '/inicio/freightliner':
              this.brand = 'freightliner';
              localStorage.setItem('brand', 'freightliner');
            break;
            default:
              this.brand = 'divemotor';
              localStorage.setItem('brand', 'divemotor');
              break;
          }
        }
        else{
          let currentBrand = localStorage.getItem('brand');
          if(currentBrand == undefined){
            this.brand = 'divemotor';
            localStorage.setItem('brand', 'divemotor');
          }
          else{
            this.brand = currentBrand;
          }
        }

        this.globalbg = banners[this.brand][0].path;

      }
    })

  }


  //Setup GA4
  setUpAnalytics() {
    this._router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'G-4FDHET1HFV',{
          'page_path': event.urlAfterRedirects,
          'page_title': event.urlAfterRedirects
        });
       }
    })
  }

}
