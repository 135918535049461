<div class="material-content">
    <mat-card>
      <div class="background-wrapper">
        <div style="position:relative;">
            <div 
              class="bgw" 
              [@fade]="toogle"  
              style="position:absolute;z-index:10"
              (@fade.done)="onFade($event)"
              [style.backgroundImage]="'url(' + bgImgs[count%bgImgs.length].path + ')'" 
              [style.backgroundPosition]="bgImgs[count%bgImgs.length].position">
            </div>
            <div
            class="bgw" 
            [style.backgroundImage]="'url(' + bgImgs[(count+bgImgs.length-1)%bgImgs.length].path + ')'" 
            [style.backgroundPosition]="bgImgs[(count+bgImgs.length-1)%bgImgs.length].position">
            </div>
        </div>

      </div>      
        <div class="buttons-container" >
        <h1 style="font-size:32px;">Agende sus servicios con nosotros</h1>
        <p style="font-size:16px;"></p>
        <br>
        <button mat-raised-button color="primary" [routerLink]="'/agendar-servicio'"  style="background: #0066CC; width:270px; border-radius: 35px !important; text-transform: uppercase;">
          <img src="/assets/images/arrow-right.png" style="margin-right: 10px;"> Agendar</button>
        <br>
        <br>
        <button mat-raised-button color="primary"  [routerLink]="'/consultar-reserva'" style="background: #0066CC; width:270px; border-radius:35px !important; text-transform: uppercase;">
          <img src="/assets/images/arrow-right.png" style="margin-right: 10px;"> Consultar reserva</button>
        <br>
        <br>
        <button mat-raised-button color="primary" [routerLink]="'/consultar-reserva/reagendar'"  style="background: #0066CC; width:270px; border-radius: 35px !important; text-transform: uppercase;">
          <img src="/assets/images/arrow-right.png" style="margin-right: 10px;"> Reagendar</button>
      </div>
  
    </mat-card>
  </div>
  
  