<div>
    <div class="popup-close-header">
        <span class="popup-close" (click)="onNoClick()">X</span>
    </div>
    
    <p><b>Estimado Cliente,</b></p>
    <p>
        A continuación los datos de su cita actual:
    </p>
    <ul>
        <li><b>Reserva: </b>{{ codigo }}</li>
        <li *ngIf="servicio"><b>Servicio: </b> {{ servicio }}</li>
        <li><b>Placa: </b> {{ placa }}</li>
        <li><b>Horario: </b>{{ fecha}} {{ hora }}</li>
        <li><b>Sucursal: </b>{{ sucursal }}</li>
        <li><b>Asesor: </b>{{ asesor }}</li>
        <li *ngIf="telefono"><b>Teléfono: </b>{{ telefono }}</li>
        <li *ngIf="email"><b>Email: </b>{{ email }}</li>
        
    </ul>
</div>

<mat-dialog-actions align="end"  *ngIf="nombre">
    <button class="button-accept" mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Continuar</button>
</mat-dialog-actions>