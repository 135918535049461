import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { Vehiculo } from 'src/app/shared/interfaces/vehiculo';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ModelsService } from 'src/app/shared/services/models.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; 
import { ModeloVehiculo } from 'src/app/shared/interfaces/modelo-vehiculo';
import { environment } from 'src/environments/environment';
import { PopupMapComponent } from '../popup-map/popup-map.component';
import { PopupSelectManagerComponent } from '../popup-select-manager/popup-select-manager.component';
import { maps } from 'src/app/data/maps';
import { PopupPlacaNotFoundComponent } from '../popup-placa-not-found/popup-placa-not-found.component';
import { PopupServiceInfo } from '../popup-service-info/popup-service-info.component';
import { PopupSchedule } from '../popup-schedule/popup-schedule.component';
import { PopupPreviusData } from '../popup-previus-data/popup-previus-data.component';
import { BookService } from 'src/app/shared/services/book.service';
import { PopupCurrentBook } from '../popup-current-book/popup-current-book.component';

declare let gtag: Function; 
declare let exponea: any; 



@Component({
  selector: 'app-rebooking',
  templateUrl: './rebooking.component.html',
  styleUrls: ['./rebooking.component.scss']
})
export class RebookingComponent implements OnInit {

   @ViewChild('stepper') private stepper: MatStepper;

   //Motivos
   motivos: any[] = [
    {
      id: '1',
      description: 'Cliente no llego a su cita'
    },
    {
      id: '2',
      description: 'Cliente no disponible en la fecha seleccionada'
    },
    {
      id: '3',
      description: 'Asesor no disponible en la fecha seleccionada'
    },
    {
      id: '4',
      description: 'Otros'
    },
    {
      id: '5',
      description: 'Cliente prueba'
    },
   ];

   //Datos recibidos
   codigo: string = '';

   //Validate Current Hour
   checkHour = 17;
   checkMinute = 0;
   checkDate = moment().hour(this.checkHour).minute(this.checkMinute).second(0);
   checkResult = moment().isAfter( this.checkDate);

    //Required Data to Step 1
    placaConsultada = false;
    clienteValido = false;
    mismoCliente = false;
    auxClientePhone = '';
    auxClienteEmail = '';

    currentVehicle: any = false;

    tiposVehiculo: any = [];

    marcaVehiculoResponse = [];
  
    getAllresponse = [];
    
    tipoVehiculoResponseOriginal = [];
    tipoVehiculoResponse: { tipo: string; codigo: string; }[] = [];
  
    modeloVehiculoResponseOriginal: Array<ModeloVehiculo> = [];
    modeloVehiculoResponse: Array<ModeloVehiculo> = [];
    
    allData : any = [];
    //Required Data to Step 2
    regiones  : any = [];
    sucursales  : any = [];
    servicios : any = [];
    promociones : any = [];

    newServices: any = [];
  
    //Required Data to Step 3
    asesores : any = [];
    horariosArray : any = [];
    proximaFechaDisponible: string = '';
  
    //FormGroups to Validate
    isLinear = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;

  
    //Aux Models
    currentMapa: any = false;
    selectedDate: Moment = moment().add(1,'d');
    minDate : Moment = moment().add(1,'d');

    //Resume Model
    resume = {
      status : "",
      codigo: "",
      modelo:'Modelo',
      placa: 'Placa',
      servicio: 'Servicio Seleccionado',
      hora: 'Hora de agenda',
      fecha:'Fecha de agenda',
      sucursal: 'Sucursal',
      asesor: 'Asesor',
      telefono: 'Telefono Asesor',
      email: 'Email Asesor',
      clienteNombre: 'Cliente nombre',
      clienteApellido: 'Apellido',
      clienteDocumento: 'Cliente DNI',
      clienteTelefono: 'Cliente Telefono',
      clienteEmail: 'Cliente Email',
      clienteObservaciones: 'Cliente Observacion',
    }
  
    constructor(
      public configuracionAgenda: ConfigurationService,
      public modelosService: ModelsService,
      public loader: NgxUiLoaderService,
      public bookService: BookService,
      private router: Router, 
       private route: ActivatedRoute,
      public dialog: MatDialog,
      private _formBuilder: FormBuilder,
      private _snackBar: MatSnackBar,      
    ) { }
  
  
    async ngOnInit(): Promise<void> {

       //Cargando toda la data
       this.fillAll();
      
      //Cargando lista de modelos
      this.fillModelosVehiculo();

      //Obteniendo Data del Vehiculo
      this.codigo = this.route.snapshot.params['codigo'];
      

      this.firstFormGroup = this._formBuilder.group({
        vehiculoPlaca: [this.route.snapshot.params['placa'], Validators.compose([Validators.required, Validators.maxLength(7), Validators.pattern('[a-zA-Z0-9]{3}[-]{0,1}[0-9]{3}')])],
        vehiculoFamilia: ['', Validators.required],
        vehiculoMarca: ['', Validators.required],
        vehiculoModelo: ['', Validators.required],
        vehiculoKilometraje: [''],
        vehiculoHorometro: [''],
      });
  
      this.secondFormGroup = this._formBuilder.group({
        servicioRegion: ['', Validators.required],
        servicioSucursal: ['', Validators.required],
        servicioSeleccionado: ['', Validators.required],
      });
      
      this.thirdFormGroup = this._formBuilder.group({
        agendaAsesor: ['', Validators.required],
        agendaFecha: [moment().add(1,'d'), Validators.required],
        agendaHorario: ['', Validators.required],
        agendaMotivo: ['4', Validators.required],
      });

      //Verificar Horario
      this.verifySchedule();
  }


  getCurrentBook(codigo){
    this.bookService.getReserva("","",codigo).subscribe({
      next: (data) => {
        console.log(data);

        if(data.length > 0){
          const dialogRef = this.dialog.open(PopupCurrentBook, {
            width: '550px',
            data: { reserva: data[0], services: this.newServices }
          });
        }

      },
      error: (err) => {
        console.log(err);
        this._snackBar.open(
          'Ocurrio un error al consultar su reserva.',
          'X',
          {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    });

    
  }

  verifySchedule(){
    if(this.checkResult){
      const dialogRef = this.dialog.open(PopupSchedule, {
        width: '550px',
      });
    }
  }


  async fillAll() {
    this.loader.start();
    this.configuracionAgenda
      .getAll()
      .subscribe((data) => {
       this.getAllresponse = data;
       this.loader.stop();

       this.onPatenteChanged( this.firstFormGroup.get('vehiculoPlaca').value.replace('-', ''));
       
      });
  }

    //Step Change Aux
  public onStepChange(event: any): void {

    //Scroll top
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    let currentStep = event.selectedIndex;

    if(currentStep == 1){

      console.log('Step 1');

      //Enviar identificacion de vehiculo a exponea
      let identifica_vh = {
        'item_patente': this.firstFormGroup.get('vehiculoPlaca').value,
        'tipo_vh': this.tipoVehiculoResponseOriginal.find((item => item.codigo == this.firstFormGroup.get('vehiculoFamilia').value)).tipo,
        'item_brand': this.marcaVehiculoResponse.find((item => item.codigo == this.firstFormGroup.get('vehiculoMarca').value)).marca,
        'item_modelo': this.firstFormGroup.get('vehiculoModelo').value,
        'kilometraje_actual': this.firstFormGroup.get('vehiculoKilometraje').value,
      }

      if(environment.production){
        exponea.track('agenda_servicio_identifica_vh', identifica_vh);
      }

    }

    if(currentStep == 2){

      //Enviar Datos de Servicio a Exponea
      let selecciona_servicio = {
        'region_servicio': this.regiones.find((item => item.Region == this.secondFormGroup.get('servicioRegion').value)).NomRegion,
        'sucursal_servicio': this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value)).NomSucursal,
        'servicio_seleccionado': this.newServices.find((item => item.codigo == this.secondFormGroup.get('servicioSeleccionado').value)).nombre
      }
      if(environment.production){
        exponea.track('agenda_servicio_selecciona_servicio', selecciona_servicio );
      }

      //Actualizar resumen
      this.resume.modelo =  this.currentVehicle.ModeloVehiculo;
      this.resume.placa =  this.currentVehicle.Placa;

      //Get Sucursal Name
      let oSucursal = this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value));
      this.resume.sucursal = oSucursal.NomSucursal;

      //Get Service Name
      let oServicio = this.newServices.find((item => item.codigo == this.secondFormGroup.get('servicioSeleccionado').value));
      this.resume.servicio = oServicio.nombre;

      //Geat Asesor Name
      let oAsesor = this.asesores.find((item => item.Asesor == this.thirdFormGroup.get('agendaAsesor').value));
      this.resume.asesor = oAsesor.NomAsesor;
      this.resume.email = oAsesor.Email;
      this.resume.telefono = oAsesor.TelefonoCellular;

      if(environment.production){
        //Event GA4
        gtag('event', 'last-step',{
          'servicio': this.resume.servicio,
          'sucursal': this.resume.sucursal
        });

        //Enviar Evento a Exponea
        exponea.track('agenda_servicio_fecha', {
          'fecha_servicio': this.resume.fecha,
          'hora_servicio': this.resume.hora,
          'asesor_servicio':  this.resume.asesor
        });
      }
    
    }
    
  }


  //OnChange Plca
  onPatenteChanged(value: string) {
    this.loader.start();
    this.modelosService.getDataVehiculo(value).subscribe((data: Vehiculo) => {

        if( data[0] !== undefined && data[0].TipoVehiculoServicio !== '' && data[0].TipoVehiculoServicio !== undefined ){
          let currentType = data[0].TipoVehiculoServicio;
          let currentMarca = data[0].CodigoMarca;
          let currentModelo = data[0].ModeloGenerico;

          //Cargando Marca
          this.firstFormGroup.get('vehiculoMarca').setValue(currentMarca);
          this.firstFormGroup.get('vehiculoMarca').disable({ onlySelf: true });
          this.onMarcaVehiculoSelected();
          
          //Cargando Tipo de Vehiculo
          this.firstFormGroup.get('vehiculoFamilia').setValue(currentType);
          this.firstFormGroup.get('vehiculoFamilia').disable({ onlySelf: true });
          this.onTipoVehiculoSelected();

          //Cargando Modelo
          if( this.modeloVehiculoResponse.filter(e => e.Mcodesd == currentModelo).length > 0){
            this.firstFormGroup.get('vehiculoModelo').setValue(currentModelo);
            this.firstFormGroup.get('vehiculoModelo').disable({ onlySelf: true });
          }
          

          //Cargando Vehiculo actual
          this.currentVehicle = data[0];

          //Cargando Reserva Actual
          this.getCurrentBook(this.codigo);
        }
        else{

          //Get Current Brand
          let currentBrand = localStorage.getItem('brand');
          if(currentBrand != 'divemotor'){
            let brand = this.marcaVehiculoResponse.find(e => e.slug == currentBrand);
            this.firstFormGroup.get('vehiculoMarca').setValue(brand.codigo);
            this.tipoVehiculoResponse = this.tipoVehiculoResponseOriginal.filter((item => brand.tipos.includes(item.codigo)));
          }
          else{
            this.firstFormGroup.get('vehiculoMarca').setValue('');
            this.firstFormGroup.get('vehiculoFamilia').setValue('');

            this.tipoVehiculoResponse = [];
            this.modeloVehiculoResponse = [];
          }

          this.firstFormGroup.get('vehiculoModelo').setValue('');

          const dialogRef = this.dialog.open(PopupPlacaNotFoundComponent, {
            width: '450px',
          });
        }

        this.placaConsultada = true;
        this.loader.stop();
    });

  }


  getPreviusData(placa){

    this.bookService.getReservaV2(placa).subscribe({
      next: (data) => {
        
        if(data.length > 0){
          const dialogRef = this.dialog.open(PopupPreviusData, {
            width: '550px',
            data: { reserva: data[0]}
          });
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
    
  }


  //OnChange Marca
  onMarcaVehiculoSelected() {
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    //Reset Next Steps to Avoid Conflict
    this.firstFormGroup.get('vehiculoFamilia').setValue('');
    this.firstFormGroup.get('vehiculoModelo').setValue('');
    this.secondFormGroup.reset({
        servicioRegion: '',
        servicioSucursal: '',
        servicioSeleccionado: '',
    });
    
    let brand = this.marcaVehiculoResponse.find(e => e.codigo == idMarca);
    this.tipoVehiculoResponse = this.tipoVehiculoResponseOriginal.filter((item => brand.tipos.includes(item.codigo)));

  }

  //OnChange Tipo
  onTipoVehiculoSelected() {

    //Reset Next Steps to Avoid Conflict
    this.firstFormGroup.get('vehiculoModelo').setValue('');
    this.secondFormGroup.reset({
      servicioRegion: '',
      servicioSucursal: '',
      servicioSeleccionado: '',
    });

    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;


    this.modeloVehiculoResponse = this.modeloVehiculoResponseOriginal.filter((item => item.MarcaId === idMarca && item.TipvehId === idTipo));

    //Cargar Servicios
    this.fillServicios(idTipo, idMarca);


  }

  //Cargando Modelos de vehiculos
  fillModelosVehiculo() {
    this.loader.start('fill-modelos');
    this.modelosService
      .getModelos()
      .subscribe((data) => {
        this.loader.stop('fill-modelos');
        this.modeloVehiculoResponseOriginal = data;
        //Cargando Marcas y Tipos
        this.fillMarcasVehiculo(data);
        this.fillTiposVehiculo(data);
        
      });
  }

  //Cargando Marcas de vehiculos
  fillMarcasVehiculo(modelos) {
    this.marcaVehiculoResponse =  this.modelosService.getMarcas(modelos);
  }





  //Cargando Tipos de vehiculos
  fillTiposVehiculo(modelos) {
    this.tipoVehiculoResponseOriginal =  this.modelosService.getTipos(modelos);
  }

  //Cargando Regiones
  fillRegiones(tipo, marca, servicio) {
    
    console.log(tipo, marca, servicio);

    //Reset Next Steps to Avoid Conflict
    this.secondFormGroup.get('servicioSucursal').setValue('');

    //Cargar regiones
    this.regiones = _.chain(this.getAllresponse)
    .filter(region => region.TipoVehiculo === tipo && region.Marca === marca && region.Servicio === servicio)
    .uniqBy('Region')
    .sort((a, b) =>  a.NomRegion.localeCompare(b.NomRegion))
    .value();

    this.regiones = [...this.regiones.filter(r => r.Region == 15), ...this.regiones.filter(r => r.Region != 15)];

  }

  //OnChance Region
  onRegionSelect() {
    this.servicios = [];
    this.promociones = [];

    //Reset Next Steps to Avoid Conflict
    this.currentMapa = false;
    this.secondFormGroup.get('servicioSucursal').setValue('');


    this.fillSucursales();
  }

  //Cargando Sucursales
  fillSucursales() {
    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;
    var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;
    var idRegion = this.secondFormGroup.get('servicioRegion').value;
    

    this.sucursales = _.chain(this.getAllresponse)
    .filter(sucursal => sucursal.TipoVehiculo === idTipo && sucursal.Marca === idMarca && sucursal.Servicio === idServicio && sucursal.Region === idRegion)
    .uniqBy('Sucursal')
    .sort((a, b) => a.NomSucursal.localeCompare(b.NomSucursal))
    .value();

    this.auxOnlyOneSucursal();
  }

  openDialog(sucursal: string,direccion: string,mapa: string): void {
    const dialogRef = this.dialog.open(PopupMapComponent, {
      width: '450px',
      data: {sucursal: sucursal, direccion: direccion, mapa:  mapa},
    });
  }


  auxOnlyOneSucursal(){
    if (this.sucursales.length == 1) {
      this.secondFormGroup.get('servicioSucursal').setValue( this.sucursales[0].Sucursal);

      //Update map link
      var idSucursal = this.secondFormGroup.get('servicioSucursal').value;

      var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
      var idMarca = this.firstFormGroup.get('vehiculoMarca').value;
      var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;
      var combinacion = idServicio + '-' + idTipo + '-' + idMarca;

      
      const currenSucursalName = this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value)).NomSucursal;
      var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.includes( combinacion)));
      if(cm == undefined){
        var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.length == 0));
      }

      this.currentMapa = {
        sucursal: currenSucursalName,
        mapa: cm
      };

      //Cargar Asesores
      this.fillAsesores( this.secondFormGroup.get('servicioSeleccionado').value);

    }
  }


  //Al cambiar Sucursal
  onSucursalSelect() {

    //Reset Next Steps to Avoid Conflict
    var idSucursal = this.secondFormGroup.get('servicioSucursal').value;

    if(idSucursal){

      var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
      var idMarca = this.firstFormGroup.get('vehiculoMarca').value;
      var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;

      var combinacion = idServicio + '-' + idTipo + '-' + idMarca;

      const currenSucursalName = this.sucursales.find((item => item.Sucursal == this.secondFormGroup.get('servicioSucursal').value)).NomSucursal;
      var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.includes( combinacion)));
      if(cm == undefined){
        var cm = maps.find(m => (m.sucursal == idSucursal && m.combinaciones.length == 0));
      }
      
      this.currentMapa = {
        sucursal: currenSucursalName,
        mapa: cm
      };


      //Cargar Asesores
      this.fillAsesores( this.secondFormGroup.get('servicioSeleccionado').value);
    
    }
    else{
      this.currentMapa = false;
    }

  }



  //Cargando Servicios
  fillServicios(tipo, marca) {

    const servicios = _.chain(this.getAllresponse)
    .filter(s => s.TipoVehiculo === tipo && s.Marca === marca)
    .uniqBy('DescServicio')
    .map(s => ({
    'codigo': s.Servicio ,
    'nombre':s.DescServicio,
    'detalle': s.DetServicio
    }))
    .value();

    this.newServices = servicios;
  }


  //Seleccionar servicios
   selectServicio(idServicio){

    this.secondFormGroup.get('servicioSeleccionado').setValue(idServicio);
    
    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    //Reset Next Steps to Avoid Conflict
    this.secondFormGroup.get('servicioRegion').setValue('');
    this.secondFormGroup.get('servicioSucursal').setValue('');
    this.currentMapa = false;

    this.fillRegiones(idTipo,idMarca, idServicio);

  }

  //Info Servicios
  infoServicio(servicio){

    const dialogRef = this.dialog.open(PopupServiceInfo, {
      width: '450px',
      autoFocus: false,
      data: servicio,
    });

  }

  //Cargando Asesores
  fillAsesores(servicio) {

    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    var idRegion = this.secondFormGroup.get('servicioRegion').value;
    var idSucursal = this.secondFormGroup.get('servicioSucursal').value;

    this.configuracionAgenda
      .getAsesores(idTipo, idMarca, idRegion, idSucursal, servicio)
      .subscribe((data) => {
        this.asesores = data;
      });
  }


  //Seleccionar Asesor
  onAsesorSelect(): void{
    //Clear current values
    this.thirdFormGroup.get('agendaHorario').setValue('');
    this.proximaFechaDisponible = '';
    this.horariosArray = [];

    this.fillCalendario(true);
  }


  //Seleccionar Fecha
  selectDate(event): void{
    this.thirdFormGroup.get('agendaFecha').setValue(event);

    //Clear current values
    this.thirdFormGroup.get('agendaHorario').setValue('');
    this.proximaFechaDisponible = '';
    this.horariosArray = [];

    this.fillCalendario(true);
  }

  selectDateMobile(event): void{
    this.thirdFormGroup.get('agendaFecha').setValue(event.value);

    //Clear current values
    this.thirdFormGroup.get('agendaHorario').setValue('');
    this.proximaFechaDisponible = '';
    this.horariosArray = [];

    this.fillCalendario(true);
  }

  //Cargar horarios
  fillCalendario(load: boolean) {

    var idTipo = this.firstFormGroup.get('vehiculoFamilia').value;
    var idMarca = this.firstFormGroup.get('vehiculoMarca').value;

    var idSucursal = this.secondFormGroup.get('servicioSucursal').value;
    var idServicio = this.secondFormGroup.get('servicioSeleccionado').value;

    var idAsesor = this.thirdFormGroup.get('agendaAsesor').value;
    var fecha = this.thirdFormGroup.get('agendaFecha').value.format('YYYYMMDD');

    if (load) this.loader.start('fill-calendario');
    this.configuracionAgenda
      .getCalendarios(idSucursal, fecha, 1, idAsesor , idServicio , idMarca, idTipo)
      .subscribe((data) => {

        //Obtener proximaFechaDisponible
        if(data.length > 0) this.proximaFechaDisponible = moment(data[0].Date).format('DD/MM/YYYY');

        //Filtrando solo la fecha solicidata
        const filterdata = data.filter(data => data.Date == fecha);
        
        const filterdata2 = _.chain(filterdata)
          .groupBy(e => e.Time)
          .map((value,key) => (
            {
              'Time': key,
              'TimeSap': value[0].TimeSap,
              "Asesores": _.chain(value).map(e => ({'nombre': this.asesores.find((item => item.Asesor == e.Pernr )).NomAsesor , 'codigo' : e.Pernr})).value(),
              'Format': (key.substring(2, 0) + ':' + key.substring(4, 2)) }
            ))
          .orderBy('Time','asc')
          .value();

        this.horariosArray = filterdata2;
        if (load) this.loader.stop('fill-calendario');
      });
  }

  selectHorario(horario: string){
  
    if( this.thirdFormGroup.get('agendaAsesor').value == ''){
      const asesoresAvailable = this.horariosArray.find(h => h.Time == horario);

      const dialogRef = this.dialog.open(PopupSelectManagerComponent, {
        width: '450px',
        autoFocus: false,
        data: {asesores : asesoresAvailable.Asesores},
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result !== undefined){
          this.thirdFormGroup.get('agendaAsesor').setValue(result);

          this,this.updateDate(horario);
        }
      });
    }
    else{
      this,this.updateDate(horario);
    }
    
  }

  updateDate(horario){
    //Geat Asesor Name
    let oAsesor = this.asesores.find((item => item.Asesor == this.thirdFormGroup.get('agendaAsesor').value));
    this.resume.asesor = oAsesor.NomAsesor;
    this.resume.email = oAsesor.Email;
    this.resume.telefono = oAsesor.TelefonoCellular;

    //Get Horario
    this.resume.fecha = moment(this.thirdFormGroup.get('agendaFecha').value.format("YYYYMMDD") + 'T' + horario ).format('DD/MM/YYYY');
    this.resume.hora = moment(this.thirdFormGroup.get('agendaFecha').value.format("YYYYMMDD") + 'T' + horario ).format('hh:mm a');

  }


  onMotivoSelect(){
    var idMotivo = this.thirdFormGroup.get('agendaMotivo').value;
    console.log(idMotivo);
  }


  //Validar datos Vehiculo
  confirmarVehiculo(){

    if(!this.firstFormGroup.valid){
      this._snackBar.open(
        'Por favor ingrese todos los datos de su vehículo.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

  }


  //Validar servicio seleccionado
  confirmarServicio(){

    let currentService = this.secondFormGroup.get('servicioSeleccionado').value;

    if(currentService == '' || currentService == undefined){
      this._snackBar.open(
        'Por favor seleccione uno de los servicios disponibles.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

  }

  //Validar horario seleccionado
  confirmarHorario(){

    const currentHorario = this.thirdFormGroup.get('agendaHorario').value;
    const currentAsesor = this.thirdFormGroup.get('agendaAsesor').value;

    if(currentHorario == '' || currentHorario == undefined){
      this._snackBar.open(
        'Por favor seleccione un horario disponible.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
      return;
    }

    if( currentAsesor == '' || currentAsesor == undefined){
      this._snackBar.open(
        'Por favor seleccione un asesor disponible.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

  }


  confirmarAgenda(){

    //Visual validation
    this.thirdFormGroup.markAllAsTouched();

    //Confirm validation last step
    if(this.thirdFormGroup.valid){

    var reagenda = {
        codigo: this.codigo,
        servicio: this.secondFormGroup.get('servicioSeleccionado').value,
        sucursal: this.secondFormGroup.get('servicioSucursal').value,
        asesor: this.thirdFormGroup.get('agendaAsesor').value,
        fecha: this.thirdFormGroup.get('agendaFecha').value.format('YYYY-MM-DD'),
        horario: this.thirdFormGroup.get('agendaHorario').value,
        motivo: this.thirdFormGroup.get('agendaMotivo').value,
    };

    this.loader.start('save-booking');
    this.configuracionAgenda
        .getReAgenda(
          reagenda.codigo,
          reagenda.servicio,
          reagenda.sucursal,
          reagenda.asesor,
          reagenda.fecha,
          reagenda.horario,
          reagenda.motivo
        )
        .subscribe((datos) => {

          this.loader.stop('save-booking');
          
          if(datos.MasterCodeCita){

            //Event GA4
            gtag('event', 'booking',{
              'fecha': reagenda.fecha,
              'hora': reagenda.horario
            });

            //Update booking code
            this.resume.codigo = datos.MasterCodeCita;
            this.resume.status = 'Ok';

            this.stepper.next();
          }
          else{
            this._snackBar.open(
              'Ocurrio un error al realizar su reserva, por favor intente nuevamente.',
              'X',
              {
                duration: 4000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
          }

        });

    }
    else{
      this._snackBar.open(
        'Por favor ingrese todos sus datos para confirmar su reserva.',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );

    }
      
  }


  atras() {
    this.router.navigate(['/inicio']);
  }


}
