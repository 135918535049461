import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-previus-sucursal-asesor',
  templateUrl: './popup-previus-sucursal-asesor.component.html',
  styleUrls: ['./popup-previus-sucursal-asesor.component.scss']
})
export class PopupPreviusSucursalAsesor implements OnInit{

  //Pre Sucursal
  region: String = '';
  sucursal: String = '';
  
  //Pre asesor
  asesor: String = '';

  //Pre contacto
  nombre: String = '';
  email: String = '';
  telefono: String = '';

  constructor(
    public dialogRef: MatDialogRef<PopupPreviusSucursalAsesor>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}


  ngOnInit() {
      if(this.data.asesor){
        this.asesor = this.data.asesor;
      }

      if(this.data.region){
        this.region = this.data.region;
        this.sucursal = this.data.sucursal;
      }

      if(this.data.nombre){
        this.nombre = this.data.nombre;
        this.email = this.data.email;
        this.telefono = this.data.telefono;
      }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
