import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModeloVehiculo } from '../interfaces/modelo-vehiculo';
import { ModelosDetailResponse } from '../interfaces/modelos-detail-response';
import { TipoVehiculoResponse } from '../interfaces/tipo-vehiculo-response';
import { Vehiculo } from '../interfaces/vehiculo';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  modelosDetailResponse: ModelosDetailResponse[];
  listaSetResponse: TipoVehiculoResponse[];

  constructor(private http: HttpClient, private httpClient: HttpClient) {

  }

  public getModelos(): Observable<ModeloVehiculo[]> {
    let modelos: Array<ModeloVehiculo> = [];
    return this.getSAPModels().pipe(
      map((data: any) => {
        this.modelosDetailResponse = data.d.results;
        this.modelosDetailResponse.forEach(function (detail) {
            modelos.push(detail);
        });
        return modelos;
      })
    );
  }

  private getSAPModels() {
    return this.http.get(environment.modelosEndPoint + "?$filter=SiteId eq '"+ environment.SiteID +"'&$format=json", {
      headers: { 'Ocp-Apim-Subscription-Key': environment.apiKey },
    });
  }


  public getTipos(modelos:ModeloVehiculo[]){

    const tipos = _.chain(modelos)
      .uniqBy('TipvehId')
      .map(t => ({
      'tipo': t.TipvehNombre,'codigo':t.TipvehId,
      }))
      .value();

    return tipos;
  }


  public getMarcas(modelos:ModeloVehiculo[]){

    const marcas = _.chain(modelos)
      .uniqBy('MarcaId')
      .filter( m => m.MarcaNombre != '')
      .map(t => ({
        'codigo': t.MarcaId,
        'slug': t.MarcaNombre.toLowerCase().replace(' ', '-'),
        'marca': t.MarcaNombre,
        'tipos': _.chain(modelos).filter(m => m.MarcaId == t.MarcaId).uniqBy('TipvehId').map(ct => ct.TipvehId).value()
      }))
      .value();
    
    return marcas;
  }


  public validateRecaptcha(response){
    let endpoint = "https://qa-recall.divemotor.com/api-recapcha-validate";

    return this.httpClient
      .post(endpoint, { "g-recaptcha-response" : response  })
      .pipe(
        map((data: any) => {
          return data;
        })
      );

  }




  public getDataVehiculo(patente): Observable<Vehiculo> {
    return this.getDataPatente(patente).pipe(
      map((data: any) => data.d.results)
    );
  }

  private getDataPatente(patente: string) {
    let endpoint = environment.movilidadEndpoint;
    endpoint +=
      "?$filter=NumeroCuenta eq '' and StringSociedades eq '' and Placa eq '" +
      patente +
      "'&$format=json";
    return this.http.get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': environment.apiKey } });
  }

  public modeloVehiculo(
    idFamilia: any,
    idMarca: any
  ): Observable<TipoVehiculoResponse[]> {
    let modeloVehiculo: Array<TipoVehiculoResponse> = [];
    const params = new HttpParams();

    let endpoint =
    environment.listaSetEndPoint +
      "?$filter=SiteId eq '" +
      environment.SiteID +
      "' and TipoLista eq '1' and IdFamilia eq '" +
      idFamilia +
      "' and IdMarca eq '" +
      idMarca +
      "'";
    endpoint += '&$format=json';
    this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': environment.apiKey } })
      .subscribe((data: any) => {
        this.listaSetResponse = data.d.results;

        this.listaSetResponse.forEach(function (lista: TipoVehiculoResponse) {

          if (
            modeloVehiculo
              .map(function (e) {
                return e.Codigo;
              })
              .indexOf(lista.Codigo) === -1
          ) {
            modeloVehiculo.push(lista);
          }
        });
      });
    return of(modeloVehiculo);
  }
}


