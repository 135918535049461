import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-popup-current-book',
  templateUrl: './popup-current-book.component.html',
  styleUrls: ['./popup-current-book.component.scss']
})
export class PopupCurrentBook implements OnInit{

  servicio: String = '';
  placa: String = '';
  nombre: String = '';
  hora: String = '';
  fecha: String = '';
  sucursal: String = '';
  asesor: String = '';
  codigo: String = '';
  telefono: String = '';
  email: String = '';

  constructor(
    public dialogRef: MatDialogRef<PopupCurrentBook>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}


  ngOnInit() {
      this.nombre = this.data.reserva.NomCuenta;
      this.placa = this.data.reserva.Placa;

      let hora = this.data.reserva.Hora;
      let horaFormat = hora.slice(2, -4).trim();
      this.hora = horaFormat.replace('H', ':');

      let fecha = this.data.reserva.Fecha;
      this.fecha = moment(fecha).format('DD/MM/YYYY');

      this.sucursal = this.data.reserva.NomSucursal;
      this.asesor = this.data.reserva.NomAsesor + ' ' + this.data.reserva.ApellAsesor;
      this.telefono = this.data.reserva.NroCuentaAsesor;
      this.codigo = this.data.reserva.CodReserva;

      let parts = this.data.reserva.ContratoMantenimiento.split('-');
      if(parts.length == 2){
        this.email = parts[0];
        let auxserv = this.data.services.find(s => s.codigo == parts[1] );
        if(auxserv){
          this.servicio = auxserv.nombre;
        }
      }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
