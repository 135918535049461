import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnulaReservaResponse } from '../interfaces/anula-reserva-response';
import { RetornaReservaResponse } from '../interfaces/retorna-reserva-response';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  retornaReservaEndPoint: string;
  anulaReservaEndPoint: string;
  smsEndpoint: string;
  apiKey: string;
  ApiKeySMS: string;
  CitaEndpoint: string;
  CitaApi: string;

  retornaReservaResponse: RetornaReservaResponse[];
  anulaReservaResponse: AnulaReservaResponse[];

  constructor(
    private httpClient: HttpClient,
  ) {
    this.retornaReservaEndPoint = environment.retornaReservaEndPoint;
    this.anulaReservaEndPoint = environment.anulaReservaEndPoint;
    this.apiKey = environment.apiKey;
    this.ApiKeySMS = environment.smsApi;
    this.smsEndpoint = environment.smsEndpoint;
    this.CitaEndpoint = environment.CitaEndpoint;
    this.CitaApi = environment.CitaApi;
  }


  public getReserva(
    rut: string,
    patente: string,
    codReserva: string,
    siteID: string =  environment.SiteID
  ): Observable<RetornaReservaResponse[]> {
    let endpoint =
    environment.retornaReservaEndPointV2 +
      "?$filter=Rut eq '" +
      rut +
      "' and Placa eq '" +
      patente +
      "' and CodReserva eq '" +
      codReserva +
      "'";
    endpoint += " and SiteId eq '"+ siteID +"'&$format=json";
    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': this.apiKey } })
      .pipe(
        map((data: any) => {
          this.retornaReservaResponse = data.d.results;
          return this.retornaReservaResponse;
        })
      );
  }

  public getReservaV2(
    patente: string,
  ): Observable<RetornaReservaResponse[]> {
    let endpoint =
      environment.retornaReservaEndPointV2 +
      "?$filter=Placa eq '" +
      patente +
      "'";
    endpoint += " and SiteId eq '"+ environment.SiteID +"'&$format=json";
    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': this.apiKey } })
      .pipe(
        map((data: any) => {
          this.retornaReservaResponse = data.d.results;
          return this.retornaReservaResponse;
        })
      );
  }

  public anulaReserva(codReserva: string): Observable<AnulaReservaResponse[]> {
    let endpoint =
      this.anulaReservaEndPoint +
      "?$filter=ReservasMasterCode eq '" +
      codReserva +
      "' and ReservasMode eq '"+ environment.SiteID +"'";
    endpoint += '&$format=json';
    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': this.apiKey } })
      .pipe(
        map((data: any) => {
          this.anulaReservaResponse = data.d.results;
          return this.anulaReservaResponse;
        })
      );
  }

}
