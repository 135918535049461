<div class="material-content" >
    <mat-card class="mat-card-form">
    
      <div class="parent">
        <div class="form" *ngIf="resume.empty" [ngClass]="{'with-border': !resume.empty}">
          <h2  *ngIf="router.url == '/consultar-reserva' " class="form-title-special">Consultar Reserva</h2>
          <h2 *ngIf="router.url == '/consultar-reserva/reagendar' " class="form-title-special">Reagendar</h2>
          <form [formGroup]="searchFormGroup" class="search-form">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Placa</mat-label>
              <input matInput 
              maxlength="6"
              placeholder="ABC123 o ABC-123" 
              formControlName="placa"
              >
            </mat-form-field>
            <span style="margin-left:10px;margin-right:10px"> </span>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>DNI/CE</mat-label>
              <input matInput 
              maxlength="12"
              placeholder="Nº de documento" 
              formControlName="dni"
              >
            </mat-form-field>
            <span style="margin-left:10px;margin-right:10px">ó</span>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Código de reserva</mat-label>
              <input matInput 
              maxlength="8"
              placeholder="KXXXXXXX" 
              formControlName="codigoReserva"
              >
            </mat-form-field>
            <span>&nbsp;</span>
           
          </form>
          <re-captcha (resolved)="resolved($event)" siteKey="6LfphFMjAAAAAGH3FI9uRlcZmKCd7U86TdK1ITIp"></re-captcha>
        </div>
        <div class="detail" *ngIf="!resume.empty">
          <h2 class="form-title-special">Detalle de tu reserva</h2>
          <div class="resume">
      
            <p matLine>
              <b>Cliente:</b> <span> {{ resume.nombre }}</span>
            </p>

            <p matLine>
              <b>Placa:</b> <span> {{ resume.placa }}</span>
            </p>
            
            <p matLine>
              <b>Fecha:</b><span> {{ resume.fecha }}</span>
            </p>
         
            <p matLine>
              <b>Hora:</b><span> {{ resume.hora }}</span>
            </p>

            <p matLine *ngIf="resume.sucursal != ''">
              <b>Sucursal:</b><span> {{ resume.sucursal }}</span>
            </p>
         
            <p matLine>
              <b>Asesor:</b><span> {{ resume.asesor }}</span>
            </p>
         
            <p matLine>
              <b>Código reserva:</b><span> {{ resume.codigo }}</span>
            </p>    
          </div>
        </div>
        <div class="options" *ngIf="!resume.empty">
          <h2 class="form-title-special">Opciones</h2>
          <div class="options-buttons">
            <button  class="button-reschedule" mat-button mat-raised-button (click)="reagendarReserva()">Reagendar</button> 
            <button  class="button-cancel-booking" mat-button mat-raised-button  color="warn" (click)="anularReserva()">Anular reserva</button>
          </div>
        </div>
      </div>
      <div class="form-buttons-container">       
        <button class="back-button" mat-button mat-raised-button [routerLink]="'/inicio'" >Volver al Inicio</button>       
        <button class="search-button" mat-button mat-raised-button  (click)="searchBook()" [disabled]="!validRecaptcha" [title]="!validRecaptcha ? 'Debe completar el Recaptcha' : ''">Buscar</button>
      </div>
    </mat-card>
</div>
  
  