import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { banners } from 'src/app/data/banners';
import { environment } from 'src/environments/environment';
import { PopupAdsComponent } from '../popup-ads/popup-ads.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger("fade", [
      transition("false=>true", [
        style({ opacity: 0 }),
        animate("2500ms 7500ms", style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  brand = 'divemotor';

  public banners: any;
  public bgImgs: Array<any>;

  toogle = false;
  count: number = 0;

  constructor(
    private store: AngularFirestore,
    private dialog: MatDialog,
    ){

    this.banners = banners;
    
    this.brand = localStorage.getItem('brand') ?? 'divemotor';
    
    this.bgImgs = this.banners[this.brand];

    this.bgImgs.forEach(e => {
        var img = document.createElement('img');
        img.src = e.path;
    });

  
  }

  ngOnInit(): void {
    //Mostrar Ads
    this.showAds();
  }


  onFade(event: any) {
      if (event.fromState){
        this.count = (this.count + 1) % this.bgImgs.length;
      }
      this.toogle = !this.toogle;
  }


  
  async showAds(){

    //Cargando lista de ads disponibles sugun marca actual
    const sucursalesRef = this.store.collection( 
      environment.collectionAds,
      ref => ref
            .where('brand', '==',this.brand )
            .where('status','==', true)
      );

      await sucursalesRef.get().subscribe((querySnapshot) => {
        const ads = querySnapshot.docs.map((doc) => doc.data());
        
        if(ads.length > 0){
          const currentAd = ads[0];

          var img = document.createElement('img');
          img.src = currentAd['image'];

          img.onload = () => {
            const dialogRef = this.dialog.open(PopupAdsComponent, {
              width: '600px',
              autoFocus: false,
              enterAnimationDuration: '800ms',
              data: {image: currentAd['image'] },
            })
          };
        }
      });

  }


}
