<div>
    <div class="popup-close-header">
        <span class="popup-close" (click)="onNoClick()">X</span>
    </div>
    
    <p><img src="./assets/images/warning-icon.png" style="vertical-align: bottom;margin-right: 5px;">  <b>Estimado Cliente,</b></p>
    <p>
        En esta sucursal su vehículo será atendido como Andes Motor - Surquillo, empresa del grupo Divemotor.
    </p>
</div>
<mat-dialog-actions align="end">
    <button class="button-decline" mat-button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>
    <button class="button-accept" mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Continuar</button>
</mat-dialog-actions>