export const maps = [
    {
        sucursal: '3103',
        combinaciones: [
            '1-3-01',
            '1-3-02',
            '1-4-01',
            '1-5-01',
            '1-5-47',
            '2-1-47',
            '2-2-47',
            '2-3-01',
            '2-3-2',
            '2-4-01',
            '2-5-01',
            '2-5-47',
            '4-3-01',
            '4-3-2',
            '4-5-47',
            '5-1-47',
            '5-2-47',
            '5-3-01',
            '5-3-02',
            '5-5-47',
            '6-3-01',
            '6-3-02',
            '6-4-01',
            '6-5-01',
            '6-5-47',
            '7-1-47',
            '7-2-47'
        ],
        direccion: 'Av. Canadá 1160',
        link: 'https://goo.gl/maps/fyAf4PPN6piP6Xyg7',
        mapa: 'https://www.google.com/maps/embed?pb=!3m2!1ses!2spe!4v1678914465303!5m2!1ses!2spe!6m8!1m7!1skGE4d6PsJWrz9s5x00fFqw!2m2!1d-12.08396793425457!2d-77.01297604613741!3f181.30890325929784!4f2.240960283082515!5f0.7820865974627469'
    },
    {
        sucursal: '3103',
        combinaciones: [
            '1-1-01',
            '1-1-14',
            '1-1-47',
            '1-2-01',
            '1-2-14',
            '1-2-15',
            '1-2-16',
            '1-2-47',
            '2-1-01',
            '2-1-14',
            '2-1-15',
            '2-2-01',
            '2-2-14',
            '2-2-16',
            '5-1-01',
            '5-1-14',
            '5-1-15',
            '5-2-01',
            '5-2-14',
            '5-2-16',
            '6-1-01',
            '6-1-14',
            '6-1-15',
            '6-2-01',
            '6-2-14',
            '6-2-16',
            '7-1-01',
            '7-2-01'
        ],
        direccion: 'Av. Nicolás Arriola 550',
        link: 'https://goo.gl/maps/fyAf4PPN6piP6Xyg7',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.3959002263464!2d-77.0135655!3d-12.0850268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c880a06b71a9%3A0x23ee061f00fba1a8!2sAv%20Nicol%C3%A1s%20Arriola%20550%2C%20La%20Victoria%2015034!5e0!3m2!1ses!2spe!4v1678914342777!5m2!1ses!2spe'
    },
    {
        sucursal: '3103',
        combinaciones: [
            '4-1-01',
            '4-1-14',
            '4-1-15',
            '4-1-47',
            '4-2-01',
            '4-2-14',
            '4-2-16',
            '4-2-47'
        ],
        direccion: 'Calle Alejandro Bussalleu  171',
        link: 'https://goo.gl/maps/fyAf4PPN6piP6Xyg7',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.3824271797953!2d-77.0137817!3d-12.0859509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c87e37086ff1%3A0x47440ffe9beaf57c!2sAlejandro%20Bussalleu%20171%2C%20La%20Victoria%2015034!5e0!3m2!1ses!2spe!4v1678914651922!5m2!1ses!2spe'
    },
    {
        sucursal: '3103',
        combinaciones: [],
        direccion: 'Av. Canadá 1160',
        link: 'https://goo.gl/maps/fyAf4PPN6piP6Xyg7',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15605.639763195531!2d-77.0130105!3d-12.0840637!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8786002e243a6c7e!2sDivemotor%20Av%20Canad%C3%A1!5e0!3m2!1ses-419!2spe!4v1662279048129!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3106',
        combinaciones: [],
        direccion: 'Av.  Elmer Faucett 217',
        link: 'https://goo.gl/maps/9Ne1NhMt29piZUbw8',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.592031519482!2d-77.09891689999999!3d-12.0715665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9617caa2e19%3A0x40eecf6e9a3bd23f!2sAv.%20Elmer%20Faucett%20217%2C%20San%20Miguel%2015088!5e0!3m2!1ses!2spe!4v1662278418429!5m2!1ses!2spe'
    },
    {
        sucursal: '3110',
        combinaciones: [],
        direccion: 'Av. Aramburú 1197',
        link: 'https://goo.gl/maps/vWogwq3R9M4T4ChJ8',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.148765729028!2d-77.01684089999999!3d-12.101966400000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c873fcae1af9%3A0xa53b4e2ee7fed59!2sAv.%20Andr%C3%A9s%20Arambur%C3%BA%201197%2C%20San%20Isidro%2015036!5e0!3m2!1ses!2spe!4v1661788368307!5m2!1ses!2spe'
    },
    {
        sucursal: '3113',
        combinaciones: [],
        direccion: 'Mariscal Castilla 4708.',
        link: 'https://goo.gl/maps/7BJgw5GjYok8zKyM7',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.2634141163494!2d-75.2376902!3d-12.025377800000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x910ebde598a7de89%3A0x3be076b941fff012!2sDivemotor%20Huancayo%20-%20Taller%20Jeep%2C%20Mercedes-benz%2C%20Dodge%2C%20Ram%2C%20Fiat!5e0!3m2!1ses!2spe!4v1661788402847!5m2!1ses!2spe'
    },
    {
        sucursal: '3117',
        combinaciones: [],
        direccion: 'Carretera Huaraz-Monterrey KM',
        link: 'https://goo.gl/maps/kXERwwPJxjcbzH3w8',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3935.218823944509!2d-77.5364877!3d-9.4896873!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91a91298509a14e5%3A0x3b0d72466a294ba!2sDivemotor%20-%20Vehiculos%20Comerciales!5e0!3m2!1ses!2spe!4v1661789012261!5m2!1ses!2spe'
    },
    {
        sucursal: '3118',
        combinaciones: [],
        direccion: 'Av. Panamericana Sur Km. 24.5',
        link: 'https://goo.gl/maps/BSheocGF3X6CHWccA',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3899.021330465824!2d-76.936075!3d-12.2468349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105bb972fcfba1f%3A0x7322cf60c2952cd3!2sDIVEMOTOR!5e0!3m2!1ses-419!2spe!4v1662278477861!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3119',
        combinaciones: [],
        direccion: 'Parque Piura Futura, Lote 6D-2. Veintiséis de Octubre',
        link: 'https://goo.gl/maps/i2NzVsr3hk7dUKck6',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63576.71708403872!2d-80.6765101!3d-5.1766561!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904a10719bdaa151%3A0x6d8c5f24dfb70775!2sDivemotor%20Piura!5e0!3m2!1ses-419!2spe!4v1662278520046!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3120',
        combinaciones: [],
        direccion: 'Av. Teodoro Varcarcel 983',
        link: 'https://goo.gl/maps/iZ9nraWF8iicFPEE8',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.0726600780954!2d-79.03161519999999!3d-8.094072999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3ded4424afed%3A0x93a609132bf9bcf2!2sDIVEMOTOR%20-%20Taller%20Mercedes-Benz%2FJeep%2FDodge%2FFiat%20Trujillo!5e0!3m2!1ses-419!2spe!4v1662278564954!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3121',
        combinaciones: [],
        direccion: 'Av. Vía de Evitamiento Norte 2',
        link: 'https://goo.gl/maps/8jHaPMQmJKQNk6d18',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.8318578653134!2d-78.5161789!3d-7.145432899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91b25af478ce258d%3A0x4701d94b2a988e95!2sDIVEMOTOR%20-%20Taller%20Mercedes-Benz%2FJeep%2FDodge%2FFiat%20Cajamarca!5e0!3m2!1ses-419!2spe!4v1662278605489!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3122',
        combinaciones: [],
        direccion: 'Carretera Panamericana Norte k',
        link: 'https://goo.gl/maps/q5UcKoFp7F6hz62n7',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3962.4315694070774!2d-79.8971276!3d-6.7170729!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904ced059561087f%3A0x5fba5e9a78e9d608!2sDive%20motor%20Chiclayo!5e0!3m2!1ses-419!2spe!4v1662278638559!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3131',
        combinaciones: [],
        direccion: 'Sector La Florida San Lorenzo',
        link: 'https://goo.gl/maps/w37ZXRnDXo2FwDyTA',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3879.8038686756277!2d-72.071315!3d-13.48618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916dd11fefce53d1%3A0xfe2f62e08bb916a6!2sDIVEMOTOR%20CACHIMAYO!5e0!3m2!1ses-419!2spe!4v1662278680661!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3138',
        combinaciones: [],
        direccion: 'Av. Mártires 4 de Noviembre N° 2342 Mz J-26 lote 6-D',
        link: 'https://goo.gl/maps/21iuuq2RNNkDjJWv7',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.2512315073636!2d-70.1198629!3d-15.524657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9167f5f6d97ff65f%3A0xae8555f4b19d15a0!2sDivemotor%20Taller%20Jeep%2C%20Mercedes-Benz%2C%20Dodge%2C%20Ram%2C%20Fiat!5e0!3m2!1ses-419!2spe!4v1662278719424!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3140',
        combinaciones: [],
        direccion: 'Av. Javier Prado 5507',
        link: 'https://goo.gl/maps/hAcKuiuPzEk915Y76',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31211.8438731632!2d-77.0051267!3d-12.0792237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c798a8412ad7%3A0x205212b139e640ff!2sMercedes-Benz%20La%20Molina!5e0!3m2!1ses-419!2spe!4v1662278911491!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3142',
        combinaciones: [],
        direccion: 'Av. Javier Prado Este 5237',
        link: 'https://goo.gl/maps/hPhhepG9LiUtvVMF9',
        mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.5016169507066!2d-76.9653432!3d-12.0777734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c655ee909129%3A0xd39986d0061b7091!2sDivemotor%20Camacho!5e0!3m2!1ses-419!2spe!4v1662278869073!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3146',
        combinaciones: [],
        direccion: 'Av. Ramón Castilla Mz. 10 Lt. 1 Villa de Pasco 3era. Etapa',
        link: 'https://goo.gl/maps/7GrhKchfbmxWycwFA',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d979.8254950600092!2d-76.2418912!3d-10.7881675!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91086f9f4c6d2981%3A0xe883b25b58953056!2sDivemotor%20-%20Taller%20Villa%20De%20Pasco!5e0!3m2!1ses-419!2spe!4v1662278836297!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3150',
        combinaciones: [],
        direccion: 'Variante Uchumayo km 5',
        link: 'https://goo.gl/maps/nmonfoWnp2zRcMbJ8',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7654.7433112081235!2d-71.59479099406829!3d-16.40594041657513!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x31c9c8c9979c4122!2sDIVEMOTOR!5e0!3m2!1ses-419!2spe!4v1662278801537!5m2!1ses-419!2spe'
    }
    ,{
        sucursal: '3153',
        combinaciones: [],
        direccion: 'Av. Javier Prado 5635',
        link: 'https://goo.gl/maps/uCrV1hyTQ4KUKg3m8',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3901.5733941534713!2d-76.9596776!3d-12.0728462!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c6f995afffff%3A0x7c7196f7208850ed!2sAv.%20Javier%20Prado%20Este%205635%2C%20La%20Molina%2015023!5e0!3m2!1ses-419!2spe!4v1662278755862!5m2!1ses-419!2spe'
    },
    {
        sucursal: '3158',
        combinaciones: [],
        direccion: 'Av Tomás Marsano 280',
        link: 'https://maps.app.goo.gl/RhrzXMsi7vTHjAub6',
        mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d975.2708044607426!2d-77.0174225!3d-12.1064554!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c87347b3cf39%3A0x28614b36280801f4!2sFastLane%20Motors%20%7C%20Surquillo!5e0!3m2!1ses-419!2spe!4v1698763148065!5m2!1ses-419!2spe'
    }
];